import { combineReducers } from 'redux';

import couponReducer from './CouponReducer';
import metricsReaducer from './MetricsReducer';
import therapyReducer from './TherapyReducer';
import uiReducer from './UIReducer';

export const rootReducer = combineReducers({
  ui: uiReducer,
  doctorMetrics: metricsReaducer,
  coupon: couponReducer,
  therapy: therapyReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
