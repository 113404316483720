import React from 'react';
import { Breadcrumb } from 'antd';
import StyledBreadcrumb from '../../components/styledBreadcrumb';

const Dashboard = () => {
  return (
    <div>
      <StyledBreadcrumb>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </StyledBreadcrumb>
      <div className="md:flex justify-center items-center h-64">
        <p className="text-blue-800 font-bold text-xl">
          Welcome to the Dashboard
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
