import { AppState } from '../../reducers';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as types from '../../types';

export const startLoader = () => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.START_LOADER,
    payload: {
      loader: true
    }
  });
};

export const stopLoader = () => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.STOP_LOADER,
    payload: {
      loader: false
    }
  });
};

export const setMode = (mode: string) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.SET_ENV_MODE,
    mode
  });
};
