import { Checkbox, Collapse, Radio } from 'antd';
import CheckboxGroup, { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FormikProps, withFormik } from 'formik';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Loader from '../../../../components/loader';
import { actionCreators } from '../../../../redux/actions/ActionCreators';
import { Category, SubCategory } from '../../../../redux/actions/therapyAction/datatypes';
import { AppState } from '../../../../redux/reducers';
import { AddSeviceFormValues, Props, State } from './addService';

const { Panel } = Collapse;

class AddService extends PureComponent<
  Props & FormikProps<AddSeviceFormValues>,
  State
> {
  componentDidMount() {
    this.props.getCategoriesAndSubCategories();
  }

  onCategoryChange = (e: RadioChangeEvent) => {
    const { setFieldValue, categories } = this.props;
    categories.forEach((category) => {
      if (category.name === e.target.value) {
        setFieldValue('category', category);
      }
    });
  };

  onSubCategorySelected = (values: CheckboxValueType[]) => {
    const { setFieldValue, subCategories } = this.props;
    const selectedSubCategories = subCategories
      .filter((subCategory) => values.includes(subCategory.name))
      .map((subCategory) => subCategory);
    setFieldValue('subCategories', selectedSubCategories);
  };

  render() {
    const {
      categories,
      subCategories,
      isLoading,
      values,
      handleChange,
      handleSubmit,
    } = this.props;
    return (
      <div className="w-full">
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <h3 className="flex justify-center text-xl text-bold text-gray-700 mt-4">
              Add Service
            </h3>
            <div className="bg-white py-4">
              <p className="text-base font-semibold text-gray-700 flex justify-center">
                Select Category
              </p>
              <section className="flex justify-evenly">
                <Radio.Group
                  value={values.category?.name}
                  onChange={this.onCategoryChange}
                >
                  {categories.map((category: Category) => (
                    <Radio.Button key={category._id} value={category.name}>
                      {category.name}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </section>
              <p className="text-base font-semibold text-gray-700 flex justify-center py-4">
                Select Sub-Category
              </p>
              <section>
                <CheckboxGroup onChange={this.onSubCategorySelected}>
                  <div className="flex px-12 flex-wrap mb-4">
                    {subCategories.map((subCategory: SubCategory) => (
                      <div className="w-1/5 h-12">
                        <Checkbox
                          key={subCategory.name}
                          value={subCategory.name}
                        >
                          {subCategory.name}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </CheckboxGroup>
              </section>
              <div className="flex justify-end">
                <button
                  className="flex justify-center items-center bg-primary text-white focus:outline-none focus:shadow-none font-bold py-2 px-4 rounded-full mr-4"
                  type="submit"
                >
                  Add Service
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

const EnhancedForm = withFormik<Props, AddSeviceFormValues>({
  mapPropsToValues: (values) => {
    return { category: values.categories[0], subCategories: [] };
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onAddService({
      name: values.category?.name,
      avatar: values.category?.avatar,
      subCategories: values.subCategories,
      isActive: true,
    });
    resetForm();
    props.onCancel();
  },
})(AddService);

const mapStateToProps = (state: AppState) => ({
  isLoading: state.therapy.isLoading,
  categories: state.therapy.categories,
  subCategories: state.therapy.subCategories,
});

const mapDispatchToProp = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProp)(EnhancedForm);
