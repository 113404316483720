import React, { PureComponent } from 'react';
import { compose, AnyAction, bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Layout, Breadcrumb, Row, Button, Table } from 'antd';

import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { actionCreators } from '../../redux/actions/ActionCreators';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { ProcutCategoriesProps } from './productCategory';

class ProductCategories extends PureComponent<ProcutCategoriesProps> {
  categoryCreateHandler = () => {
    const { history } = this.props;
    history.push('/createproductcategories');
  };

  render() {
    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>product categories</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledLayout>
          <Row type="flex" justify="end" align="middle">
            <CategoriesBtnWrapper>
              <Button
                icon="plus-circle"
                onClick={this.categoryCreateHandler}
                type="primary"
              >
                Product Categories
              </Button>
            </CategoriesBtnWrapper>
          </Row>
          <Table
            rowKey={(record: any) => record.code}
            size="small"
            columns={[]}
            dataSource={[]}
            bordered
            // expandRowByClick
            // expandedRowRender={record => this.expandedRowRender(record)}
          />
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductCategories);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
  min-height: 80vh;
`;

const CategoriesBtnWrapper = styled.div`
  padding: 20px;
`;
