import './style.css';

import { Avatar, Breadcrumb, Icon, Layout, Modal, Popconfirm, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';

import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { Catalogue, SubCategory } from '../../redux/actions/therapyAction/datatypes';
import { AppState } from '../../redux/reducers';
import { Props, State } from './catalogue';
import AddCatalogue from './components/addCatalogue';
import EditCatalogue from './components/editCatalogue';

class Catalogues extends PureComponent<Props, State> {
  columns: ColumnProps<any>[] = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      render: (avatar: string) => {
        return <Avatar size="large" src={avatar} />;
      },
    },
    {
      title: 'Category Name',
      dataIndex: 'name',
    },
    {
      title: 'Sub Categories',
      dataIndex: 'subCategory',
      render: (subCategory: Array<SubCategory>) =>
        subCategory.map((subCategory) => (
          <Tag key={subCategory.name}>{subCategory.name}</Tag>
        )),
    },
    {
      title: 'Edit',
      dataIndex: 'Action',
      align: 'center',
      render: (_: any, record: Catalogue) => (
        <Icon
          type="edit"
          theme="outlined"
          onClick={() => this.onEditHandler(record)}
        />
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'Action',
      align: 'center',
      render: (_: any, record: Catalogue) => (
        <Popconfirm
          title="Do you want yo delete？"
          okText="Yes"
          cancelText="No"
          onConfirm={() => this.props.deleteCatalogue(record._id)}
        >
          <Icon type="delete" theme="outlined" />
        </Popconfirm>
      ),
    },
  ];

  state: State = {
    addCatalogue: false,
    editCatalogue: false,
    catalogue: null,
  };

  componentDidMount() {
    this.props.getCatalogue();
  }

  toggleAddCatalogue = () => {
    this.setState((prevState) => ({
      addCatalogue: !prevState.addCatalogue,
    }));
  };

  onEditHandler = (record: Catalogue) => {
    this.setState({ catalogue: record });
    this.toggleEditCatalogue();
  };

  toggleEditCatalogue = () => {
    this.setState((prevState) => ({
      editCatalogue: !prevState.editCatalogue,
    }));
  };

  render() {
    const { addCatalogue, editCatalogue, catalogue } = this.state;
    const {
      catalogues,
      isLoading,
      catalogueHasError,
      catalogueErrorMsg,
    } = this.props;

    if (catalogueHasError) {
      return (
        <div className="h-full flex justify-center items-center">
          <p className="text-base font-semibold text-gray-800">
            {catalogueErrorMsg}
          </p>
        </div>
      );
    }

    return (
      <div>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Therapy</Breadcrumb.Item>
          <Breadcrumb.Item>Add Catalogue</Breadcrumb.Item>
        </StyledBreadcrumb>

        <Modal
          width={1200}
          visible={addCatalogue}
          footer={null}
          onCancel={this.toggleAddCatalogue}
          destroyOnClose
        >
          <AddCatalogue onCancel={this.toggleAddCatalogue} />
        </Modal>

        <Modal
          width={1200}
          visible={editCatalogue}
          footer={null}
          onCancel={this.toggleEditCatalogue}
          destroyOnClose
        >
          <EditCatalogue
            // @ts-ignore
            catalogue={catalogue}
            onCancel={this.toggleEditCatalogue}
          />
        </Modal>
        <StyledLayout>
          <div className="flex justify-end my-4">
            <button
              className="flex justify-center items-center bg-primary text-white focus:outline-none focus:shadow-none font-bold py-2 px-4 rounded-full mr-4"
              onClick={this.toggleAddCatalogue}
            >
              Add Catalogue
            </button>
          </div>
          <Table
            rowKey={(record: any) => record.code}
            size="small"
            columns={this.columns}
            dataSource={catalogues}
          />
        </StyledLayout>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.therapy.isLoading,
  catalogues: state.therapy.catalogues,
  catalogueHasError: state.therapy.catalogueHasError,
  catalogueErrorMsg: state.therapy.catalogueErrorMsg,
});

const mapDispatchToProp = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProp)(Catalogues);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
`;
