import React from 'react';
import { Result, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';

const Four0Four: React.FC<RouteComponentProps> = props => {
  const { history } = props;
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          Back Home
        </Button>
      }
    />
  );
};

export default withRouter(Four0Four);
