import moment from 'moment';
import {
  MetricsState,
  MetricsActionType,
  DoctorsModel,
  IllnessModel,
  Metrics
} from '../actions/metricsAction/datatypes';
import * as types from '../types';

const yesterday = moment()
  .subtract(1, 'days')
  .format('YYYY-MM-DD');
const tomorrowPlusOne = moment()
  .add(2, 'days')
  .format('YYYY-MM-DD');

const initialState: MetricsState = {
  illness: [],
  doctors: [],
  metrics: [],
  total: 0,
  selectedRangePicker: {
    fromDate: yesterday,
    toDate: tomorrowPlusOne
  },
  selectedDoctor: [],
  selectedIllness: [],
  selectedTreatment: [],
  selectedStatus: [],
  csv: [],
  medOrderMetrics: [],
  medOrderTotal: 0,
  orderType: [
    { label: 'All', value: 'all' },
    {
      label: 'Consultation Medicine Order',
      value: 'CONSULTATION_MEDICINE_ORDER'
    },
    { label: 'User Request Medicine', value: 'DIRECT_MED_ORDER' }
  ],
  selectedOrderType: ['all'],
  selectedMedOrderStatus: []
};

const getConsultationMetricsData = (
  state: MetricsState,
  action: MetricsActionType
) => {
  const { illness, doctors, metrics, total } = action.payload as Metrics;
  let updatedDoctors: Array<any> = [];
  if (doctors.length > 0) {
    doctors.forEach((doctor: DoctorsModel) => {
      updatedDoctors.push({
        label: doctor.name,
        value: doctor.name,
        ...doctor
      });
    });
  }
  let updateIllness: Array<any> = [];
  if (illness.length > 0) {
    illness.forEach((illness: IllnessModel) => {
      updateIllness.push({
        label: illness.name,
        value: illness.name,
        ...illness
      });
    });
  }

  return {
    ...state,
    illness: updateIllness,
    doctors: updatedDoctors,
    metrics,
    total
  };
};

const metricsReaducer = (state = initialState, action: MetricsActionType) => {
  switch (action.type) {
    case types.GET_METRICS_DATA_SUCCESS:
      return getConsultationMetricsData(state, action);
    case types.GET_METRICS_DATA_FAILURE:
      return getConsultationMetricsData(state, action);
    case types.SET_RANGE_PICKER: {
      return {
        ...state,
        selectedRangePicker: action.payload
      };
    }
    case types.SET_MED_SYS: {
      return {
        ...state,
        selectedTreatment: action.payload
      };
    }
    case types.SET_ILLNESS: {
      return {
        ...state,
        selectedIllness: action.payload
      };
    }
    case types.SET_DOCTOR: {
      return {
        ...state,
        selectedDoctor: action.payload
      };
    }
    case types.SET_STATUS: {
      return {
        ...state,
        selectedStatus: action.payload
      };
    }
    case types.DOWNLOAD_CSV_SUCCESS: {
      return {
        ...state,
        csv: action.payload
      };
    }
    case types.DOWNLOAD_CSV_FAILURE: {
      return {
        ...state,
        csv: action.payload
      };
    }
    case types.GET_MED_ORDER_METRICS: {
      const { medOrderMetrics, medOrderTotal } = action.payload;
      return {
        ...state,
        medOrderMetrics,
        medOrderTotal
      };
    }
    case types.SET_ORDER_TYPE: {
      return {
        ...state,
        selectedOrderType: action.payload
      };
    }
    case types.SET_MED_ORDER_STATUS: {
      return {
        ...state,
        selectedMedOrderStatus: action.payload
      };
    }
    default:
      return state;
  }
};

export default metricsReaducer;
