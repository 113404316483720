import { CloseCircleFilled } from '@ant-design/icons';
import { Popconfirm, Popover } from 'antd';
import React, { useState } from 'react';

import { SubCategory } from '../../../../redux/actions/therapyAction/datatypes';

interface ISubCategoryTag {
  subCategory: SubCategory;
  index: number;
  sIndex: number;
  onDeleteSubCategory(index: number, sIndex: number): void;
}

export const SubCategoryTag = ({
  subCategory,
  index,
  sIndex,
  onDeleteSubCategory,
}: ISubCategoryTag) => {
  const [visible, setVisible] = useState<boolean>(false);

  const onDelete = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    onDeleteSubCategory(index, sIndex);
  };

  return (
    <Popover visible={visible} trigger="click">
      <div className="rounded-full px-1 flex justify-around items-center mr-2 bg-primary cursor-pointer mb-2">
        <div
          className="w-full"
          onClick={(e) => {
            e.stopPropagation();
            setVisible(!visible);
          }}
        >
          <p className="text-white text-xs m-0 cursor-pointer">
            {subCategory.name}
          </p>
        </div>
        <div className="px-1 pb-2 z-50">
          <Popconfirm
            title="Are you sure delete?"
            onConfirm={onDelete}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <CloseCircleFilled style={{ color: 'white' }} />
          </Popconfirm>
        </div>
      </div>
    </Popover>
  );
};
