export const convertToPhoneArray = (phone: string) => {
  if (!phone) {
    return [];
  }
  phone.replace(/(^[,\s]+)|([,\s]+$)/g, '');
  const ArrPhone = phone.split(/[ ,"\n"]+/);
  const result = ArrPhone.map(item => {
    if (item.length === 10) {
      return `+91${item}`;
    }
    return item;
  });
  return result;
};
