import { PlusCircleFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import React, { useState } from 'react';

import { IHours } from '../../addTherapyCentre';
import SlotPopOverContent from '../slotPopOverContent';

interface IAddSlot {
  index: number;
  onAddSlot(hours: IHours[], index: number): void;
}

export const AddSlotButton = ({ onAddSlot, index }: IAddSlot) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <Popover
      visible={visible}
      content={
        <SlotPopOverContent
          setVisible={setVisible}
          index={index}
          buttonName="Create"
          onAddSlot={onAddSlot}
        />
      }
      trigger="click"
    >
      <div
        onClick={() => setVisible(!visible)}
        className="cursor-pointer rounded-full border px-2 py-1 flex flex-row justify-around items-center"
      >
        <PlusCircleFilled style={{ color: '#1DA57A' }} />
        <p className="m-0 text-primary text-xs pl-1">Add New Slot</p>
      </div>
    </Popover>
  );
};
