import React from 'react';
import styled from 'styled-components';
import { Cascader } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { DrProps } from './MedOrderStatusPicker';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';

const MedOrderStatusPicker: React.FC<DrProps> = props => {
  const options = [
    { value: 'REQ_MED', label: 'Reqested' },
    { value: 'SENT_QUOT', label: 'Quoted' },
    { value: 'ORDERED', label: 'Ordered' },
    { value: 'PACKED', label: 'Packed' },
    { value: 'SHIPPED', label: 'Shipped' },
    { value: 'ORD_CLOSED', label: 'Order Closed' },
    { value: 'ATTACH_VALID_PRESCRIPTION', label: 'Attach Valid Prescription' },
    { value: 'USER_NOT_REACHABLE', label: 'User Not Reachable' },
    { value: 'PHARMA_CLOSED_ORDER', label: 'Pharma Closed Order' }
  ];

  const { setMedOrderStatus, selectedMedOrderStatus } = props;

  const onChange = (value: any) => {
    setMedOrderStatus(value);
  };

  return (
    <MedSysWrapper>
      <Cascader
        options={options}
        onChange={value => onChange(value)}
        placeholder="Select Status"
        defaultValue={selectedMedOrderStatus}
      />
    </MedSysWrapper>
  );
};

export const mapStateToProps = (state: AppState) => ({
  selectedMedOrderStatus: state.doctorMetrics.selectedMedOrderStatus
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedOrderStatusPicker);

const MedSysWrapper = styled.div`
  padding-bottom: 20px;
`;
