import { TherapyActionType, TherapyState } from '../actions/therapyAction/datatypes';
import * as types from '../types';

const initialState: TherapyState = {
  isLoading: false,
  formSubmitted: false,
  categories: [],
  categoryHasError: false,
  categoryErrorMsg: null,
  selectedCategory: null,
  subCategories: [],
  subCategoryHasError: false,
  subCategoryErrorMsg: null,
  selectedSubCategory: null,
  catalogues: [],
  catalogueHasError: false,
  catalogueErrorMsg: null,
  therapyCenterCreated: false,
  therapyCenterLoading: false,
  therapyCenters: [],
  therapyCentersHasError: false,
  therapyCentreErrorMsg: null,
  userProfiles: [],
  userProfileHasError: false,
  userProfileErrorMsg: null,
};

const therapyReducer = (state = initialState, action: TherapyActionType) => {
  const { payload, type } = action;
  switch (type) {
    case types.CREATE_CATEGORY: {
      return {
        ...state,
        categories: payload,
      };
    }
    case types.CREATE_SUBCATEGORY: {
      return {
        ...state,
        subCategories: payload,
      };
    }
    case types.CREATE_CATALOGUE: {
      return {
        ...state,
        catalogues: payload,
      };
    }
    case types.UPDATE_CATEGORY: {
      return {
        ...state,
        categories: payload,
      };
    }
    case types.UPDATE_SUBCATEGORY: {
      return {
        ...state,
        subCategories: payload,
      };
    }
    case types.UPDATE_CATALOGUE: {
      return {
        ...state,
        catalogues: payload,
      };
    }
    case types.CATEGORY_ERROR: {
      return {
        ...state,
        categories: initialState.categories,
        categoryHasError: true,
        categoryErrorMsg: payload,
      };
    }
    case types.SUBCATEGORY_ERROR: {
      return {
        ...state,
        subCategories: initialState.subCategories,
        subCategoryHasError: true,
        subCategoryErrorMsg: payload,
      };
    }
    case types.CATALOGUE_ERROR: {
      return {
        ...state,
        catalogues: initialState.catalogues,
        catalogueHasError: true,
        catalogueErrorMsg: payload,
      };
    }
    case types.FORM_SUBMIT: {
      return {
        ...state,
        formSubmitted: payload,
      };
    }
    case types.LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case types.FETCH_CATEGORIES_AND_SUBCATEGORIES: {
      return {
        ...state,
        categories: payload.categories,
        subCategories: payload.subCategories,
      };
    }
    case types.FETCH_CATEGORIES_AND_SUBCATEGORIES_ERROR: {
      return {
        ...state,
        categoryHasError: true,
        categoryErrorMsg: payload,
        subCategoryHasError: true,
        subCategoryErrorMsg: payload,
      };
    }
    case types.FETCH_SUBCATEGORIES: {
      return {
        ...state,
        subCategories: payload,
      };
    }
    case types.FETCH_SUBCATEGORIES_ERROR: {
      return {
        ...state,
        subCategoryHasError: true,
        subCategoryErrorMsg: payload,
      };
    }
    case types.SELECTED_CATEGORY: {
      return {
        ...state,
        selectedCategory: payload,
      };
    }
    case types.REMOVE_AVATAR_FROM_CATEGORY: {
      return {
        ...state,
        selectedCategory: payload,
      };
    }
    case types.SELECTED_SUBCATEGORY: {
      return {
        ...state,
        selectedSubCategory: payload,
      };
    }
    case types.REMOVE_AVATAR_FROM_SUBCATEGORY: {
      return {
        ...state,
        selectedSubCategory: payload,
      };
    }
    case types.FETCH_CATALOGUE: {
      return {
        ...state,
        catalogues: payload,
      };
    }
    case types.CREATE_THERAPY_CENTER: {
      return {
        ...state,
        therapyCenterCreated: payload,
      };
    }
    case types.THERAPY_CENTER_LOADER: {
      return {
        ...state,
        therapyCenterLoading: payload,
      };
    }
    case types.FETCH_THERAPY_CENTERS: {
      return {
        ...state,
        therapyCenters: payload,
      };
    }
    case types.FETCH_THERAPY_CENTERS_ERROR: {
      return {
        ...state,
        therapyCentreErrorMsg: payload,
        therapyCentersHasError: true,
      };
    }
    case types.FETCH_THERAPY_USER_PROFILE: {
      return {
        ...state,
        userProfiles: payload,
      };
    }
    case types.FETCH_THERAPY_USER_PROFILE_ERROR: {
      return {
        ...state,
        userProfileHasError: true,
        userProfileErrorMsg: payload,
      };
    }
    default:
      return state;
  }
};

export default therapyReducer;
