import { CloseCircleFilled } from '@ant-design/icons';
import { Icon, Popconfirm } from 'antd';
import Upload from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import * as firebase from 'firebase';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';

import { actionCreators } from '../../../../redux/actions/ActionCreators';
import { AppState } from '../../../../redux/reducers';
import { EditSubCategoryFormValues, Props } from './editSubCategoryModalContent';

const EditSubCategoryModelContent: React.FC<
  Props & FormikProps<EditSubCategoryFormValues>
> = (props) => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = props;

  const onUpload = async (uploadObj: any) => {
    try {
      const { file } = uploadObj;
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      const uploadTask = uploadRef.child(path).put(file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadObj.onProgress({ percent: progress });
        },
        (error: any) => {
          uploadObj.onError(error.code);
          setFieldValue('avatar', '');
        },
        async () => {
          const imgUrl = await uploadTask.snapshot.ref.getDownloadURL();
          uploadObj.file.thumbUrl = imgUrl;
          uploadObj.onSuccess({ imgUrl });
          setFieldValue('avatar', imgUrl);
        }
      );
    } catch (error) {
      console.log(error);
      setFieldValue('avatar', '');
    }
  };

  const onRemove = async (file: UploadFile) => {
    try {
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      uploadRef.child(path).delete();
      setFieldValue('avatar', '');
    } catch (error) {
      console.log(error);
      setFieldValue('avatar', '');
    }
  };

  return (
    <div>
      <p className="text-lg font-medium text-center">Edit Sub-Category</p>
      <form
        className="flex flex-col justify-center px-20 py-4"
        onSubmit={handleSubmit}
      >
        <section className="mb-4">
          <p className="block text-grey-700 text-base font-bold pr-2 mb-2">
            Sub-Category Name
          </p>
          <input
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Ex: Neck Pain"
          />
          {errors.name && touched.name && (
            <span className="mt-3 text-red-500 pl-2">{errors.name}</span>
          )}
        </section>
        <div className="flex">
          <section className="w-1/2 mr-2 mb-4">
            <p className="block text-grey-700 text-base font-bold pr-2 mb-2">
              Duration
            </p>
            <input
              name="value"
              value={values.value}
              onChange={handleChange}
              onBlur={handleBlur}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Ex: 30"
            />
            {errors.value && touched.value && (
              <span className="mt-3 text-red-500 pl-2">{errors.value}</span>
            )}
          </section>
          <section className="w-1/2 ml-2 mb-4">
            <p className="block text-grey-700 text-base font-bold pr-2 mb-2">
              unit
            </p>
            <div className="relative">
              <select
                name="unit"
                value={values.unit}
                onChange={handleChange}
                onBlur={handleBlur}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:scale-50"
              >
                <option>Select Unit</option>
                <option value="hr">Hours</option>
                <option value="min">Minutes</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {errors.unit && touched.unit && (
              <span className="mt-3 text-red-500 pl-2">{errors.unit}</span>
            )}
          </section>
        </div>
        <section className="mb-4">
          <p className="block text-grey-700 text-base font-bold pr-2 mb-2">
            Sub-Category Avatar
          </p>
          {values.avatar !== null && values.avatar !== '' ? (
            <div className="flex">
              <div className="relative">
                <img
                  className="w-20 h-20 rounded-full items-center"
                  src={values.avatar}
                  alt={values.name}
                />
                <div className="absolute top-0 right-0 cursor-pointer">
                  <Popconfirm
                    title="Do you want yo delete？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={props.removeAvatarFromSubCategory}
                  >
                    <CloseCircleFilled />
                  </Popconfirm>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Upload.Dragger
                customRequest={(option) => onUpload(option)}
                onRemove={onRemove}
                multiple={false}
                name="files"
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Upload.Dragger>
              {errors.avatar && touched.avatar && (
                <span className="mt-3 text-red-500 pl-2">{errors.avatar}</span>
              )}
            </>
          )}
        </section>
        <div className="flex justify-center">
          <button
            type="submit"
            className="w-20 bg-primary text-white focus:outline-none focus:shadow-none font-bold py-2 px-4 rounded-full"
          >
            Edit
          </button>
        </div>
      </form>
    </div>
  );
};

const EnhancedForm = withFormik<Props, EditSubCategoryFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    if (props.selectedSubCategory) {
      return {
        id: props.selectedSubCategory._id || '',
        name: props.selectedSubCategory.name || '',
        avatar: props.selectedSubCategory.avatar || '',
        unit: props.selectedSubCategory.duration.unit || '',
        value: props.selectedSubCategory.duration.value.toString() || '',
      };
    } else {
      return {
        id: '',
        name: '',
        avatar: '',
        unit: '',
        value: '',
      };
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name required'),
    unit: Yup.string().required('Unit required'),
    value: Yup.string().required('Duration required'),
    avatar: Yup.string().required('Avatar required'),
  }),
  handleSubmit: (value, { props, resetForm }) => {
    props.updateSubCategory({
      id: value.id,
      name: value.name,
      avatar: value.avatar,
      duration: {
        unit: value.unit,
        value: Number(value.value),
      },
    });
    if (props.formSubmitted) {
      resetForm();
      return props.onCancel();
    }
  },
})(EditSubCategoryModelContent);

const mapStateToProps = (state: AppState) => ({
  formSubmitted: state.therapy.formSubmitted,
  selectedSubCategory: state.therapy.selectedSubCategory,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);
