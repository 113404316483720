import React, { PureComponent } from 'react';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Button,
  Table,
  Tag,
  Steps,
  Avatar,
  DatePicker
} from 'antd';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { MedOrderMetricProps, State } from './medOrderMetric';
import moment from 'moment';
import {
  Events,
  MedOrderMetricsModel
} from '../../redux/actions/metricsAction/datatypes';
import OrderTypePicker from '../../components/orderTypePicker';

import AyurvedaImg from '../../assets/ayurveda.png';
import SiddhaImg from '../../assets/siddha.png';
import HomeopathyImg from '../../assets/homeopathy.png';
import PillsImg from '../../assets/pills.png';
import MedSysPicker from '../../components/medSysPicker';
import StatusPicker from '../../components/medOrderStatusPicker';
import { PaginationConfig } from 'antd/lib/pagination';

const { Step } = Steps;
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';
class MedOrderMetrics extends PureComponent<MedOrderMetricProps, State> {
  state: State = {
    pagination: {},
    sortOrder: 'descend'
  };

  column = [
    {
      title: 'User',
      dataIndex: 'username',
      render: (text: string, record: any) => {
        let icon;
        switch (record.treatment) {
          case 'Ayurveda':
            icon = AyurvedaImg;
            break;
          case 'Siddha':
            icon = SiddhaImg;
            break;
          case 'Homeopathy':
            icon = HomeopathyImg;
            break;
          default:
            icon = PillsImg;
            break;
        }

        return (
          <div style={{ display: 'flex' }}>
            <Avatar size="small" src={icon} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 8
              }}
            >
              <span>{text}</span>
              <i style={{ color: '#757575', fontSize: '12px' }}>
                {record.userPhoneMask}
              </i>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Requested Date',
      dataIndex: 'events',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (events: Events, record: any) => {
        const time = moment(record.createddate).utcOffset('+05:30');
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{time.format('DD/MM/YYYY')}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>
              {time.format('hh:mm A')}
            </i>
          </div>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'displayStatus',
      render: (type: any, record: any) => {
        switch (type) {
          case 'REQ_MED': {
            return (
              <Tag color="blue" key={type}>
                {type}
              </Tag>
            );
          }
          case 'SENT_QUOT': {
            return (
              <Tag color="purple" key={type}>
                {type}
              </Tag>
            );
          }
          case 'ORDERED': {
            return (
              <Tag color="olive" key={type}>
                {type}
              </Tag>
            );
          }
          case 'PACKED': {
            return (
              <Tag color="gold" key={type}>
                {type}
              </Tag>
            );
          }
          case 'ORD_CLOSED': {
            return (
              <Tag color="red" key={type}>
                {type}
              </Tag>
            );
          }
          case 'SHIPPED': {
            return (
              <Tag color="green" key={type}>
                {type}
              </Tag>
            );
          }
          case 'ATTACH_VALID_PRESCRIPTION': {
            return (
              <Tag color="orange" key={type}>
                {type}
              </Tag>
            );
          }
          case 'USER_NOT_REACHABLE': {
            return (
              <Tag color="orange" key={type}>
                {type}
              </Tag>
            );
          }
          default:
            break;
        }
      }
    },
    {
      title: 'Pharmacy',
      dataIndex: 'pharmaName',
      render: (pharmaName: string, record: any) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{pharmaName}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>
              {record.treatment}
            </i>
          </div>
        );
      }
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType',
      render: (type: any, record: any) => {
        switch (type) {
          case 'CONSULTATION_MEDICINE_ORDER':
            return (
              <div>
                <Tag color="#008080" key={type}>
                  {type}
                </Tag>
                <p>{record.lang}</p>
              </div>
            );
          case 'DIRECT_MED_ORDER':
            return (
              <div>
                <Tag color="#FF00FF" key={type}>
                  {type}
                </Tag>
                <p>{record.lang}</p>
              </div>
            );
          default:
            return <div />;
        }
      }
    },
    {
      title: 'Updated At',
      dataIndex: 'updateddate',
      render: (date: any, record: any) => {
        const updateddateData = record.events[record.events.length - 1];
        const time = moment(updateddateData.updateddate).utcOffset('+05:30');
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{time.format('DD/MM/YYYY')}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>
              {time.format('hh:mm A')}
            </i>
          </div>
        );
      }
    },
    {
      title: 'Price',
      dataIndex: 'discountAmount',
      render: (amount: string, record: any) => {
        if (amount)
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>₹{amount}</span>
            </div>
          );
        return <div />;
      }
    },
    {
      title: 'Tracking No',
      dataIndex: 'trackno',
      render: (track: string, record: any) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{track}</span>
          </div>
        );
      }
    }
  ];

  componentDidMount() {
    const { sortOrder } = this.state;
    const { getMedOrderMetricsData } = this.props;
    getMedOrderMetricsData({ page: 1, pageSize: 10, sortOrder });
  }

  componentDidUpdate(prevProps: MedOrderMetricProps) {
    const { getMedOrderMetricsData, mode } = this.props;
    const { sortOrder } = this.state;
    if (prevProps.mode !== mode) {
      getMedOrderMetricsData({ page: 1, pageSize: 10, sortOrder });
    }
  }

  expandedRowRender = (records: MedOrderMetricsModel) => {
    return (
      <Steps
        direction="vertical"
        current={NaN}
        size="small"
        key={moment().format('x')}
      >
        {records.events.map(step => {
          return (
            <Step
              key={moment(step.updateddate).format('x')}
              status="process"
              title={step.displayStatus}
              description={
                <i style={{ color: 'rgba(0,0,0,.65)', fontSize: '12px' }}>
                  {moment(step.updateddate)
                    .utcOffset('+05:30')
                    .format('DD/MM/YYYY hh:mm A')}
                </i>
              }
            />
          );
        })}
      </Steps>
    );
  };

  onRangePickerChange = (data: any) => {
    const { setRangePicker } = this.props;
    if (data.length > 0) {
      const fromDate = moment(data[0]).format('YYYY-MM-DD');
      const toDate = moment(data[1]).format('YYYY-MM-DD');
      return setRangePicker({ fromDate, toDate });
    }
    return setRangePicker({ fromDate: '', toDate: '' });
  };

  handleFilter = () => {
    const { getMedOrderMetricsData } = this.props;
    const { sortOrder } = this.state;
    getMedOrderMetricsData({ page: 1, pageSize: 10, sortOrder });
  };

  onTableChange = async (pagination: PaginationConfig, _: any, sorter: any) => {
    const { getMedOrderMetricsData } = this.props;
    this.setState({ sortOrder: sorter.order });
    getMedOrderMetricsData({
      page: pagination.current,
      pageSize: 10,
      sortOrder: sorter.order
    });
  };

  render() {
    const { medOrderMetrics, medOrderTotal, loader, dateRange } = this.props;

    const { pagination } = this.state;

    let defaultRangePicker: Array<any> = [];

    if (
      dateRange.fromDate &&
      dateRange.toDate &&
      dateRange.fromDate !== '' &&
      dateRange.toDate !== ''
    ) {
      defaultRangePicker = [
        moment(dateRange.fromDate, 'YYYY-MM-DD'),
        moment(dateRange.toDate, 'YYYY-MM-DD')
      ];
    }

    let updatedPagination = { ...pagination, total: medOrderTotal };

    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Medicine Order Metrics</Breadcrumb.Item>
        </StyledBreadcrumb>

        <div style={styles.comcontainer}>
          <Row type="flex" justify="space-around">
            <Col span={4}>
              <MedSysPicker />
            </Col>
            <Col span={4}>
              <OrderTypePicker />
            </Col>

            <Col span={4}>
              <StatusPicker />
            </Col>
          </Row>

          <Row type="flex" justify="space-around">
            <Col span={6}>
              <RangePickerWrapper>
                <RangePicker
                  format={dateFormat}
                  defaultValue={defaultRangePicker}
                  onChange={this.onRangePickerChange}
                />
              </RangePickerWrapper>
            </Col>
            <Col span={2}>
              <Button onClick={() => this.handleFilter()} type="primary">
                Search
              </Button>
            </Col>
            <Col span={4}>
              {/* <Button type="primary" onClick={() => this.csvHandler()}>
                  Export as csv
                </Button> */}
            </Col>

            <Col span={4}>{/* <Count>Count : {total}</Count> */}</Col>
          </Row>
        </div>

        <StyledLayout>
          <Table
            rowKey={(record: any) => record.orderId}
            bordered={false}
            size="small"
            // @ts-ignore
            columns={this.column}
            dataSource={medOrderMetrics}
            expandedRowRender={record => this.expandedRowRender(record)}
            pagination={updatedPagination}
            onChange={this.onTableChange}
            loading={loader}
          />
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loader: state.ui.loader,
  medOrderMetrics: state.doctorMetrics.medOrderMetrics,
  medOrderTotal: state.doctorMetrics.medOrderTotal,
  dateRange: state.doctorMetrics.selectedRangePicker,
  csv: state.doctorMetrics.csv,
  mode: state.ui.mode || 'dev'
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MedOrderMetrics);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
`;

const RangePickerWrapper = styled.div`
  padding-bottom: 20px;
`;

const Count = styled.h4`
  font-variant: tabular-nums;
  font-feature-settings: 'tnum', 'tnum';
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-top: 5px;
`;

const styles = {
  comcontainer: {
    paddingTop: '20px'
  }
};
