import React from 'react';
import styled from 'styled-components';
import { Cascader } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { DrProps } from './StatusPicker';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';

const StatusPicker: React.FC<DrProps> = props => {
  const options = [
    { value: '0', label: 'Pending' },
    { value: '1', label: 'Completed' },
    { value: '2', label: 'Cancelled' }
  ];

  const { setStatus, selectedStatus } = props;

  const onChange = (value: any) => {
    setStatus(value);
  };

  return (
    <MedSysWrapper>
      <Cascader
        options={options}
        onChange={value => onChange(value)}
        placeholder="Select Status"
        defaultValue={selectedStatus}
      />
    </MedSysWrapper>
  );
};

export const mapStateToProps = (state: AppState) => ({
  selectedStatus: state.doctorMetrics.selectedStatus
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StatusPicker);

const MedSysWrapper = styled.div`
  padding-bottom: 20px;
`;
