import React, { PureComponent } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

export default class Loader extends PureComponent {
  render() {
    return (
      <SpinnerContainer>
        <Spin size="large" />
      </SpinnerContainer>
    );
  }
}
