import React from 'react';
import styled from 'styled-components';
import { Cascader } from 'antd';
import { IllnessProps } from './illnessPicker';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';

const IllnessPicker: React.FC<IllnessProps> = props => {
  const { illness, setIllness, selectedIllness } = props;
  const onChange = (value: any) => {
    setIllness(value);
  };

  return (
    <MedSysWrapper>
      <Cascader
        options={illness}
        onChange={value => onChange(value)}
        placeholder="Select Illness"
        defaultValue={selectedIllness}
      />
    </MedSysWrapper>
  );
};

export const mapStateToProps = (state: AppState) => ({
  illness: state.doctorMetrics.illness,
  selectedIllness: state.doctorMetrics.selectedIllness
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IllnessPicker);

const MedSysWrapper = styled.div`
  padding-bottom: 20px;
`;
