import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators, compose } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { connect } from 'react-redux';
import { Form, Breadcrumb, Row, Select, Input, Col } from 'antd';
import { CouponForm } from '../createCoupon/createCoupon';
import { FormComponentProps } from 'antd/lib/form';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

class CreateProduct extends PureComponent<FormComponentProps> {
  render() {
    const { form } = this.props;
    // const { submitLoading } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const mode = getFieldValue('mode');
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 6 }
    };

    getFieldDecorator('keys', {
      initialValue: [0],
      rules: [{ required: true, message: 'Terms and Condition required' }]
    });

    const keys = getFieldValue('keys');

    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Create Product</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledLayout>
          <Row type="flex" justify="center">
            <Col span={24} offset={6}>
              <Form {...formItemLayout} onSubmit={() => {}}>
                <Col span={24} offset={6}>
                  <StyledH1>Create Coupon</StyledH1>
                </Col>
                <Form.Item label="Name" hasFeedback>
                  {getFieldDecorator('name', {
                    rules: [
                      { required: true, message: 'Product name required' }
                    ]
                  })(<Input placeholder="Product name" />)}
                </Form.Item>
                <Form.Item label="Description" hasFeedback>
                  {getFieldDecorator('des', {
                    rules: [{ required: true, message: 'Description required' }]
                  })(<TextArea rows={5} placeholder="Product Description" />)}
                </Form.Item>
                <Form.Item label="Seller" hasFeedback>
                  {getFieldDecorator('seller', {
                    rules: [{ required: true, message: 'Seller name required' }]
                  })(<Input placeholder="Seller name" />)}
                </Form.Item>
                <Form.Item label="Medical System" hasFeedback>
                  {getFieldDecorator('medSystem', {
                    rules: [
                      { required: true, message: 'Medical System required' }
                    ]
                  })(
                    <Select placeholder="Select Medical System">
                      <Option value="siddha">Siddha</Option>
                      <Option value="ayurveda">Ayurveda</Option>
                      <Option value="homeopathy">Homeopathy</Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Tags" hasFeedback>
                  {getFieldDecorator('tags', {
                    rules: [{ required: true, message: 'Tags required' }]
                  })(
                    <Select placeholder="Select Tags">
                      <Option value="kid's health">Kid's Health</Option>
                      <Option value="men's health">Men's Health</Option>
                      <Option value="women's health">Women's Health</Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Price" hasFeedback>
                  {getFieldDecorator('price', {
                    rules: [{ required: true, message: 'Price required' }]
                  })(<Input placeholder="Price" />)}
                </Form.Item>
                <Form.Item label="Offer Price" hasFeedback>
                  {getFieldDecorator('offerPrice', {
                    rules: [{ required: true, message: 'Offer Price required' }]
                  })(<Input placeholder="Offer Price" />)}
                </Form.Item>
                <Form.Item label="Offer Label" hasFeedback>
                  {getFieldDecorator('offerLabel', {
                    rules: [{ required: true, message: 'Offer Label required' }]
                  })(<Input placeholder="Offer Label" />)}
                </Form.Item>
                <Form.Item label="Stock" hasFeedback>
                  {getFieldDecorator('stock', {
                    rules: [{ required: true, message: 'Stock required' }]
                  })(<Input placeholder="Stock" />)}
                </Form.Item>
                <Form.Item label="Unit Type" hasFeedback>
                  {getFieldDecorator('type', {
                    rules: [{ required: true, message: 'Unit type required' }]
                  })(<Input placeholder="Unit Type" />)}
                </Form.Item>
                <Form.Item label="Unit Value" hasFeedback>
                  {getFieldDecorator('value', {
                    rules: [{ required: true, message: 'Unit Value' }]
                  })(<Input placeholder="Unit Value" />)}
                </Form.Item>
                <Form.Item label="ManuScript Tamil" hasFeedback>
                  {getFieldDecorator(
                    'tam',
                    {}
                  )(<TextArea rows={5} placeholder="ManuScript Tamil" />)}
                </Form.Item>
                <Form.Item label="ManuScript English" hasFeedback>
                  {getFieldDecorator(
                    'eng',
                    {}
                  )(<TextArea rows={5} placeholder="ManuScript English" />)}
                </Form.Item>
                <Form.Item label="ManuScript Hindi" hasFeedback>
                  {getFieldDecorator(
                    'hin',
                    {}
                  )(<TextArea rows={5} placeholder="ManuScript Hindi" />)}
                </Form.Item>
                <Form.Item label="ManuScript Malayalam" hasFeedback>
                  {getFieldDecorator(
                    'mal',
                    {}
                  )(<TextArea rows={5} placeholder="ManuScript Malayalam" />)}
                </Form.Item>
                <Form.Item label="ManuScript Telugu" hasFeedback>
                  {getFieldDecorator(
                    'tel',
                    {}
                  )(<TextArea rows={5} placeholder="ManuScript Telugu" />)}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create<CouponForm & FormComponentProps>({
    name: 'CreateCoupon'
  })
)(CreateProduct);

const StyledLayout = styled.div`
  background: #fff;
  border-radius: 5px;
`;

const StyledH1 = styled.h1`
  font-size: 20px;
  margin: 16px 0;
`;
