import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useDebounce } from '../../../../hooks/debounceHook';
import { fetchTherapyCenters } from '../../../../redux/actions/therapyAction';

export const DebouncedSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 900);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(searchTerm);
    dispatch(
      fetchTherapyCenters({ search: searchTerm, online: '0', isActive: '0' })
    );
  }, [debouncedSearchTerm, dispatch, searchTerm]);

  return (
    <Search
      placeholder="Search Therapy Centre"
      onSearch={(value) => setSearchTerm(value)}
      enterButton
      width={180}
    />
  );
};
