import { CouponForm } from '../../../screens/createCoupon/createCoupon';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { AnyAction } from 'redux';
import { api } from '../../../utils/api';
import * as types from '../../types';
import { CreateCouponReturnType } from './datatypes';
import { async } from 'q';

export const createCoupon = (
  couponData: CouponForm
): ThunkAction<
  Promise<CreateCouponReturnType>,
  AppState,
  null,
  AnyAction
> => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
): Promise<CreateCouponReturnType> => {
  try {
    const { data, status } = await api.post(
      '/admindashboard/coupon',
      couponData
    );

    return Promise.resolve({ message: data.message, status });
  } catch (error) {
    const { data, status } = error.response;
    return Promise.reject({ message: data.message, status });
  }
};

export const editCoupon = (
  couponData: CouponForm,
  couponid: string
): ThunkAction<
  Promise<CreateCouponReturnType>,
  AppState,
  null,
  AnyAction
> => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
): Promise<CreateCouponReturnType> => {
  try {
    const { data, status } = await api.put(
      `/admindashboard/coupon/${couponid}`,
      couponData
    );

    return Promise.resolve({ message: data.message, status });
  } catch (error) {
    const { data, status } = error.response;
    return Promise.reject({ message: data.message, status });
  }
};

export const generateVoucher = () => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  try {
    const { data } = await api.get('/admindashboard/coupon/generatevoucher');
    dispatch({
      type: types.GENERATE_VOUCHER,
      payload: data.voucherCode
    });
    return data.voucherCode;
  } catch (error) {
    // console.log(error);
  }
};

export const fetchAllCoupons = () => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  try {
    const { data } = await api.get('/admindashboard/coupons');
    dispatch({
      type: types.FETCH_ALL_COUPONS,
      payload: data.coupons
    });
  } catch (error) {
    //console.log(error);
  }
};

export const fetchAllVouchers = () => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  try {
    const { data } = await api.get('/admindashboard/coupon/vouchers');
    dispatch({
      type: types.FETCH_ALL_VOUCHERS,
      payload: data
    });
  } catch (error) {
    // console.log(error);
  }
};

export const fetchAllCouponRecords = (
  search: string | null = null,
  sort = 'descend'
) => async (dispatch: ThunkDispatch<AppState, null, AnyAction>) => {
  try {
    const route = search
      ? `/admindashboard/coupon/records?search=${search}&sort=${sort}`
      : `/admindashboard/coupon/records?sort=${sort}`;

    const { data } = await api.get(route);
    dispatch({
      type: types.FETCH_ALL_COUPON_RECORDS,
      payload: data
    });
  } catch (error) {
    // console.log(error);
  }
};
