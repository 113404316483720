import * as CouponAction from './couponAction';
import * as MetricsAction from './metricsAction';
import * as TherapyAction from './therapyAction';
import * as UIActions from './uiAction';

export const actionCreators = {
  ...UIActions,
  ...MetricsAction,
  ...CouponAction,
  ...TherapyAction,
};
