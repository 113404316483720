import { Avatar, Icon, Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import LogoImg from '../../assets/logo.png';
import { SideDrawerProps } from './sideDrawer';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface H1Props {
  hide: string;
}

const H1: React.FC<H1Props> = React.memo((props) => (
  <div {...props}>{props.children}</div>
));

const Aside: React.FC<SideDrawerProps> = (props) => {
  const { history, location } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  const navigateToPage = (path: string) => {
    history.push(path);
    setSelectedKey(path);
  };

  return (
    <Sider
      breakpoint="lg"
      theme="dark"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      style={{ zIndex: 200 }}
    >
      <Logo>
        <Avatar size="small" src={LogoImg} />
        <BrandName hide={collapsed.toString()}>Matrix</BrandName>
      </Logo>
      <Menu theme="dark" defaultSelectedKeys={[selectedKey]} mode="inline">
        <Menu.Item key="/" onClick={() => navigateToPage('/')}>
          <Icon type="dashboard" />
          <span>Dashboard</span>
        </Menu.Item>
        <SubMenu
          key="metrics"
          title={
            <span>
              <Icon type="pie-chart" />
              <span>Metrics</span>
            </span>
          }
        >
          <Menu.Item
            key="/metrics/consultation"
            onClick={() => navigateToPage('/metrics/consultation')}
          >
            Consultation
          </Menu.Item>
          <Menu.Item
            key="/metrics/medorder"
            onClick={() => navigateToPage('/metrics/medorder')}
          >
            Medicine Order
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="couponSubMenu"
          title={
            <span>
              <Icon type="tag" />
              <span>Promo</span>
            </span>
          }
        >
          <Menu.Item key="/coupon" onClick={() => navigateToPage('/coupon')}>
            Coupons
          </Menu.Item>
          <Menu.Item
            key="/createcoupon"
            onClick={() => navigateToPage('/createcoupon')}
          >
            Create Coupon
          </Menu.Item>
          <Menu.Item
            key="/vouchers"
            onClick={() => navigateToPage('/vouchers')}
          >
            Vouchers
          </Menu.Item>
          <Menu.Item
            key="/couponrecords"
            onClick={() => navigateToPage('/couponrecords')}
          >
            Coupon Records
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="productSubMenu"
          title={
            <span>
              <Icon type="shopping-cart" />
              <span>Products</span>
            </span>
          }
        >
          <Menu.Item
            key="/products"
            onClick={() => navigateToPage('/products')}
          >
            Products
          </Menu.Item>
          <Menu.Item
            key="/createproduct"
            onClick={() => navigateToPage('/createproduct')}
          >
            Create Product
          </Menu.Item>
          <Menu.Item
            key="/productcategories"
            onClick={() => navigateToPage('/productcategories')}
          >
            Product Categories
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="therapy"
          title={
            <span>
              <Icon type="fire" />
              <span>Therapy</span>
            </span>
          }
        >
          <Menu.Item
            key="/therapy/therapy_centres"
            onClick={() => navigateToPage('/therapy/therapy_centres')}
          >
            Therapy Centres
          </Menu.Item>
          <Menu.Item
            key="/therapy/therapy_user_profile"
            onClick={() => navigateToPage('/therapy/therapy_user_profile')}
          >
            User Profiles
          </Menu.Item>
          <Menu.Item
            key="/therapy/add_therapy_users"
            onClick={() => navigateToPage('/therapy/add_therapy_users')}
          >
            Add Therapy User
          </Menu.Item>
          <Menu.Item
            key="/therapy/add_therapy_centres"
            onClick={() => navigateToPage('/therapy/add_therapy_centres')}
          >
            Add Therapy Centres
          </Menu.Item>
          <Menu.Item
            key="/therapy/catalogues"
            onClick={() => navigateToPage('/therapy/catalogues')}
          >
            Catalogues
          </Menu.Item>
          <Menu.Item
            key="/therapy/categories"
            onClick={() => navigateToPage('/therapy/categories')}
          >
            Categories
          </Menu.Item>
          {/* <Menu.Item
            key="/metrics/medorder"
            onClick={() => navigateToPage('/metrics/medorder')}
          >
            Medicine Order
          </Menu.Item> */}
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default React.memo(withRouter(Aside));

const Logo = styled.div`
  height: 32px;
  margin: 8px 24px;
  display: flex;
  align-items: center;
  opacity: 1;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const BrandName = styled(H1)`
  opacity: 1;
  margin: 0 0 0 10px;
  color: #fff;
  display: ${(props: any) => (props.hide === 'true' ? 'none' : '')};
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;
