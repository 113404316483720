import {
  CouponState,
  CouponActionType
} from '../actions/couponAction/datatypes';
import * as types from '../types';

const intialState: CouponState = {
  code: '',
  coupons: [],
  vouchers: [],
  couponRecords: []
};

const couponReducer = (state = intialState, action: CouponActionType) => {
  switch (action.type) {
    case types.GENERATE_VOUCHER: {
      return {
        ...state,
        code: action.payload
      };
    }
    // case types.CREATE_COUPON: {
    //   return {
    //     ...state,
    //     coupons: action.payload
    //   };
    // }
    // case types.EDIT_COUPON: {
    //   return {
    //     ...state,
    //     coupons: action.payload
    //   };
    // }
    case types.FETCH_ALL_COUPONS: {
      return {
        ...state,
        coupons: action.payload
      };
    }

    case types.FETCH_ALL_VOUCHERS: {
      return {
        ...state,
        vouchers: action.payload
      };
    }

    case types.FETCH_ALL_COUPON_RECORDS: {
      return {
        ...state,
        couponRecords: action.payload
      };
    }

    default:
      return state;
  }
};

export default couponReducer;
