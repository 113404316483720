import React, { useState, useCallback, useContext } from 'react';
import { Form, Icon, Input, Button } from 'antd';
import { withRouter, Redirect } from 'react-router-dom';
import firebase from '../../firebaseConfig';
import { AuthContext } from '../auth';

const firebaseAuth = firebase.auth();

const LoginView: React.FC<any> = ({ form, history }) => {
  const { getFieldDecorator } = form;
  //   console.log('Rendered Login View');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      form.validateFields(async (err: any, { email, password }: any) => {
        if (!err) {
          try {
            await firebaseAuth.signInWithEmailAndPassword(email, password);
            history.push('/');
            // console.log('Logged in ************* ');
          } catch (error) {
            setErrorMessage(error.message);
            // console.log('AUTH-CALL-BACK---------> ', error.code, error.message);
          }
        }
      });
    },
    [form, history, setErrorMessage]
  );

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    // console.log('Redirect to APP ************* ');
    return <Redirect to="/" />;
  }

  return (
    <div className="login">
      <h1>Matrix</h1>
      <Form onSubmit={handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              { required: true, message: 'Please input your E-mail!' }
            ]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn btn-primary btn-block btn-large"
          >
            Sign in
          </Button>
        </Form.Item>
      </Form>
      <p className="errmsg">{errorMessage}</p>
    </div>
  );
};

const WrapLogin = Form.create({})(withRouter(LoginView));
export default WrapLogin;
