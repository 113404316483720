import { CloseCircleFilled } from '@ant-design/icons';
import { Popconfirm, Popover } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

import { IHours } from '../../addTherapyCentre';
import SlotPopOverContent from '../slotPopOverContent';

interface ITimeTag {
  hour: IHours;
  isActive: boolean;
  index: number;
  hIndex: number;
  onEditSlot(hours: IHours, index: number, hIndex: number): void;
  onDeleteSlot(index: number, hIndex: number): void;
}

export const TimeTag = ({
  hour,
  isActive,
  onEditSlot,
  index,
  hIndex,
  onDeleteSlot,
}: ITimeTag) => {
  const [visible, setVisible] = useState<boolean>(false);
  const from = moment(hour.from, 'hh:mm A').format('hh:mm A');
  const to = moment(hour.to, 'hh:mm A').format('hh:mm A');

  const onDelete = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    onDeleteSlot(index, hIndex);
  };

  return (
    <Popover
      visible={visible}
      content={
        <SlotPopOverContent
          setVisible={setVisible}
          index={index}
          hIndex={hIndex}
          hour={hour}
          onEditSlot={onEditSlot}
          buttonName="Save"
        />
      }
      trigger="click"
    >
      <div
        className={`rounded-full px-1 flex justify-around items-center ${
          isActive ? 'bg-primary' : 'bg-gray-400'
        } mr-2 cursor-pointer mb-2`}
      >
        <div
          className="w-full"
          onClick={(e) => {
            e.stopPropagation();
            setVisible(!visible);
          }}
        >
          <p className="text-white text-xs m-0 cursor-pointer">{`${from} - ${to}`}</p>
        </div>
        <div className="px-1 pb-2 z-50">
          <Popconfirm
            title="Are you sure delete?"
            onConfirm={onDelete}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <CloseCircleFilled style={{ color: 'white' }} />
          </Popconfirm>
        </div>
      </div>
    </Popover>
  );
};
