export const GET_METRICS_DATA_SUCCESS = 'GET_METRICS_DATA_SUCCESS';
export const GET_METRICS_DATA_FAILURE = 'GET_METRICS_DATA_FAILURE';
export const SET_RANGE_PICKER = 'SET_RANGE_PICKER';
export const SET_MED_SYS = 'SET_MED_SYS';
export const SET_ILLNESS = 'SET_ILLNESS';
export const SET_DOCTOR = 'SET_DOCTOR';
export const SET_STATUS = 'SET_STATUS';
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';

export const DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_CSV_SUCCESS';
export const DOWNLOAD_CSV_FAILURE = 'DOWNLOAD_CSV_FAILURE';

export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const SET_ACTIVE_DAY = 'SET_ACTIVE_DAY';

/**
 * Coupon Constants
 */
export const CREATE_COUPON = 'CREATE_COUPON';
export const EDIT_COUPON = 'EDIT_COUPON';
export const GENERATE_VOUCHER = 'GENERATE_VOUCHER';
export const FETCH_ALL_COUPONS = 'FETCH_ALL_COUPONS';
export const FETCH_ALL_VOUCHERS = 'FETCH_ALL_VOUCHERS';
export const FETCH_ALL_COUPON_RECORDS = 'FETCH_ALL_COUPON_RECORDS';

export const SET_ENV_MODE = 'SET_ENV_MODE';

export const GET_MED_ORDER_METRICS = 'GET_MED_ORDER_METRICS';
export const SET_MED_ORDER_STATUS = 'SET_MED_ORDER_STATUS';

/**
 * Therapy constants
 */
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_SUBCATEGORY = 'CREATE_SUBCATEGORY';
export const CREATE_CATALOGUE = 'CREATE_CATALOGUE';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY';
export const UPDATE_CATALOGUE = 'UPDATE_CATALOGUE';

export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const SUBCATEGORY_ERROR = 'SUBCATEGORY_ERROR';
export const CATALOGUE_ERROR = 'CATALOGUE_ERROR';

export const FETCH_CATEGORIES_AND_SUBCATEGORIES =
  'FETCH_CATEGORIES_AND_SUBCATEGORIES';
export const FETCH_CATEGORIES_AND_SUBCATEGORIES_ERROR =
  'FETCH_CATEGORIES_AND_SUBCATEGORIES_ERROR';

export const FORM_SUBMIT = 'FORM_SUBMIT';
export const LOADING = 'LOADING';

export const SELECTED_CATEGORY = 'SELECTED_CATEGORY';
export const REMOVE_AVATAR_FROM_CATEGORY = 'REMOVE_AVATAR_FROM_CATEGORY';

export const SELECTED_SUBCATEGORY = 'SELECTED_SUBCATEGORY';
export const REMOVE_AVATAR_FROM_SUBCATEGORY = 'REMOVE_AVATAR_FROM_SUBCATEGORY';

export const FETCH_CATALOGUE = 'FETCH_CATALOGUE';
export const FETCH_CATALOGUE_ERROR = 'FETCH_CATALOGUE_ERROR';
export const FETCH_SUBCATEGORIES = 'FETCH_SUBCATEGORIES';
export const FETCH_SUBCATEGORIES_ERROR = 'FETCH_SUBCATEGORIES_ERROR';
export const THERAPY_CENTER_LOADER = 'THERAPY_CENTER_LOADER';
export const CREATE_THERAPY_CENTER = 'CREATE_THERAPY_CENTER';
export const UPDATE_THERAPY_CENTER = 'UPDATE_THERAPY_CENTER';

export const FETCH_THERAPY_CENTERS = 'FETCH_THERAPY_CENTERS';
export const FETCH_THERAPY_CENTERS_ERROR = 'FETCH_THERAPY_CENTERS_ERROR';

export const FETCH_THERAPY_USER_PROFILE = 'FETCH_THERAPY_USER_PROFILE';
export const FETCH_THERAPY_USER_PROFILE_ERROR =
  'FETCH_THERAPY_USER_PROFILE_ERROR';
