import React, { PureComponent } from 'react';
import {
  Layout,
  Table,
  Tag,
  Avatar,
  DatePicker,
  Row,
  Col,
  Button,
  Steps,
  Breadcrumb
} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { CSVDownload } from 'react-csv';
import { PaginationConfig } from 'antd/lib/table';
import _ from 'underscore';

import AyurvedaImg from '../../assets/ayurveda.png';
import SiddhaImg from '../../assets/siddha.png';
import HomeopathyImg from '../../assets/homeopathy.png';

import MedSysPicker from '../../components/medSysPicker';
import IllnessPicker from '../../components/illnessPicker';
import DrPicker from '../../components/drPicker';
import StatusPicker from '../../components/statusPicker';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import Loader from '../../components/loader';

import { AppState } from '../../redux/reducers';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { MetricsModel } from '../../redux/actions/metricsAction/datatypes';

import { MetricProps, State } from './metric';

const { Step } = Steps;
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

class ConsultationMetrics extends PureComponent<MetricProps, State> {
  state: State = {
    sortOrder: 'descend',
    pagination: {}
  };

  columns = [
    {
      title: 'Doctor',
      dataIndex: 'doctorname',
      render: (text: string, record: MetricsModel) => {
        let icon;
        switch (record.treatment) {
          case 'Ayurveda':
            icon = AyurvedaImg;
            break;
          case 'Siddha':
            icon = SiddhaImg;
            break;
          case 'Homeopathy':
            icon = HomeopathyImg;
            break;
          default:
            break;
        }
        return (
          <Row type="flex">
            <Col>
              <Avatar size="small" src={icon} />
            </Col>
            <Col>
              <StyledP>{text}</StyledP>
              <StyledP>{record.lang}</StyledP>
            </Col>
          </Row>
        );
      }
    },
    {
      title: 'Consult At',
      dataIndex: 'apdate',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (text: string) => {
        const time = moment(text).utcOffset('+05:30');
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{time.format('DD/MM/YYYY')}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>
              {time.format('hh:mm A')}
            </i>
          </div>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'displayStatus',
      render: (text: string) => {
        switch (text) {
          case 'CONSULTATION_BOOK': {
            return (
              <Tag color="blue" key={text}>
                {text}
              </Tag>
            );
          }
          case 'CONSULTATION_COMPLETED': {
            return (
              <Tag color="green" key={text}>
                {text}
              </Tag>
            );
          }

          case 'MARK_FOLLOW_UP': {
            return (
              <Tag color="green" key={text}>
                {text}
              </Tag>
            );
          }

          case 'CONSULTATION_NOT_RECOMMENDED': {
            return (
              <Tag color="green" key={text}>
                {text}
              </Tag>
            );
          }
          default: {
            console.log(text);
            return (
              <Tag color="red" key={text}>
                {text}
              </Tag>
            );
          }
        }
      }
    },
    {
      title: 'User',
      dataIndex: 'username',
      render: (text: string, record: MetricsModel) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{text}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>
              {record.userPhoneMask}
            </i>
          </div>
        );
      }
    },
    {
      title: 'Updated At',
      dataIndex: 'events',
      render: (history: any, record: MetricsModel) => {
        if (history && history.length > 0) {
          const statusTime = _.last<any>(history).updateddate;
          const time = moment(statusTime).utcOffset('+05:30');
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{time.format('DD/MM/YYYY')}</span>
              <i style={{ color: '#757575', fontSize: '12px' }}>
                {time.format('hh:mm A')}
              </i>
            </div>
          );
        }
        return '';
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      width: 120,
      render: (remarks: any) => {
        if (remarks && remarks.length > 0) {
          const remarksItem = _.map(
            remarks,
            (remark: string, index: number) => <p key={index}>{remark}</p>
          );
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {remarksItem}
            </div>
          );
        }
        return '';
      }
    },
    {
      title: 'Payment',
      dataIndex: 'transid'
    }
  ];

  componentDidMount() {
    const { getConsultationMetricsData } = this.props;
    const { sortOrder } = this.state;
    getConsultationMetricsData({
      page: 1,
      pageSize: 10,
      sortOrder
    });
  }

  componentDidUpdate(prevProps: MetricProps) {
    const { getConsultationMetricsData, mode } = this.props;
    const { sortOrder } = this.state;
    if (prevProps.mode !== mode) {
      getConsultationMetricsData({ page: 1, pageSize: 10, sortOrder });
    }
  }

  expandedRowRender = (records: MetricsModel) => {
    return (
      <Steps
        direction="vertical"
        current={NaN}
        size="small"
        key={moment().format('x')}
      >
        {records.events.map(step => {
          return (
            <Step
              key={moment(step.updateddate).format('x')}
              status="process"
              title={step.displayStatus}
              description={
                <i style={{ color: 'rgba(0,0,0,.65)', fontSize: '12px' }}>
                  {moment(step.updateddate)
                    .utcOffset('+05:30')
                    .format('DD/MM/YYYY hh:mm A')}
                </i>
              }
            />
          );
        })}
      </Steps>
    );
  };

  onRangePickerChange = (data: any) => {
    const { setRangePicker } = this.props;
    if (data.length > 0) {
      const fromDate = moment(data[0]).format('YYYY-MM-DD');
      const toDate = moment(data[1]).format('YYYY-MM-DD');
      return setRangePicker({ fromDate, toDate });
    }
    return setRangePicker({ fromDate: '', toDate: '' });
  };

  handleFilter = () => {
    const { getConsultationMetricsData } = this.props;
    const { sortOrder } = this.state;
    getConsultationMetricsData({
      page: 1,
      pageSize: 10,
      sortOrder
    });
  };

  csvHandler = () => {
    const { downloadCSV } = this.props;
    downloadCSV();
  };

  onTableChange = async (pagination: PaginationConfig, _: any, sorter: any) => {
    const { getConsultationMetricsData } = this.props;
    getConsultationMetricsData({
      page: pagination.current,
      pageSize: 10,
      sortOrder: sorter.order
    });
  };

  render() {
    const { metrics, total, loader, dateRange, csv } = this.props;

    let { pagination } = this.state;

    let defaultRangePicker: Array<any> = [];

    if (
      dateRange.fromDate &&
      dateRange.toDate &&
      dateRange.fromDate !== '' &&
      dateRange.toDate !== ''
    ) {
      defaultRangePicker = [
        moment(dateRange.fromDate, 'YYYY-MM-DD'),
        moment(dateRange.toDate, 'YYYY-MM-DD')
      ];
    }

    let updatedPagination = { ...pagination, total: total };

    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Doctor Metrics</Breadcrumb.Item>
        </StyledBreadcrumb>

        <StyledLayout>
          <div style={styles.comcontainer}>
            <Row type="flex" justify="space-around">
              <Col span={4}>
                <MedSysPicker />
              </Col>
              <Col span={4}>
                <DrPicker />
              </Col>
              <Col span={4}>
                <IllnessPicker />
              </Col>
              <Col span={4}>
                <StatusPicker />
              </Col>
            </Row>

            <Row type="flex" justify="space-around">
              <Col span={6}>
                <RangePickerWrapper>
                  <RangePicker
                    format={dateFormat}
                    defaultValue={defaultRangePicker}
                    onChange={this.onRangePickerChange}
                  />
                </RangePickerWrapper>
              </Col>
              <Col span={2}>
                <Button onClick={() => this.handleFilter()} type="primary">
                  Search
                </Button>
              </Col>

              <Col span={4}>
                <Button type="primary" onClick={() => this.csvHandler()}>
                  Export as csv
                </Button>
              </Col>

              <Col span={4}>
                <Count>Count : {total}</Count>
              </Col>
            </Row>
          </div>

          <Table
            rowKey={(record: any) => record.appointmentid}
            bordered={false}
            size="small"
            // @ts-ignore
            columns={this.columns}
            dataSource={metrics}
            expandedRowRender={record => this.expandedRowRender(record)}
            pagination={updatedPagination}
            onChange={this.onTableChange}
            loading={loader}
          />

          {csv.length > 0 ? (
            <CSVDownload data={csv} target="_blank"></CSVDownload>
          ) : null}
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loader: state.ui.loader,
  metrics: state.doctorMetrics.metrics,
  total: state.doctorMetrics.total,
  dateRange: state.doctorMetrics.selectedRangePicker,
  csv: state.doctorMetrics.csv,
  mode: state.ui.mode || 'dev'
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationMetrics);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
`;

const RangePickerWrapper = styled.div`
  padding-bottom: 20px;
`;

const Count = styled.h4`
  font-variant: tabular-nums;
  font-feature-settings: 'tnum', 'tnum';
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-top: 5px;
`;

const StyledP = styled.p`
  padding-left: 8px;
  margin: 0;
`;

const styles = {
  comcontainer: {
    paddingTop: '20px'
  }
};
