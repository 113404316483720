import { notification } from 'antd';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { history } from '../../../router/history';
import {
  AddCategoryFormValues,
} from '../../../screens/categories/components/addCategoryModelContent/addCategoryModalContent';
import {
  EditCategoryFormValues,
} from '../../../screens/categories/components/editCategoryModelContent/editCategoryModalContent';
import { api } from '../../../utils/api';
import { axiosErrorHandler } from '../../../utils/axiosError';
import { AppState } from '../../reducers';
import * as types from '../../types';
import { Catalogue, Category, SubCategory } from './datatypes';

export const formSubmitted = (value: boolean) => ({
  type: types.FORM_SUBMIT,
  payload: value,
});

export const loading = (value: boolean) => ({
  type: types.LOADING,
  payload: value,
});

export const getCategoriesAndSubCategories = () => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  try {
    dispatch(loading(true));
    const { data } = await api.get('/admindashboard/therapy/category');
    const { categories, subCategories } = data;
    dispatch(loading(false));
    dispatch({
      type: types.FETCH_CATEGORIES_AND_SUBCATEGORIES,
      payload: {
        categories,
        subCategories,
      },
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch({
      type: types.FETCH_CATEGORIES_AND_SUBCATEGORIES_ERROR,
      payload: error.message,
    });
    dispatch(loading(false));
  }
};

export const createCategory = (
  category: AddCategoryFormValues
): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  try {
    const { data } = await api.post(
      '/admindashboard/therapy/category',
      category
    );
    dispatch(formSubmitted(true));
    dispatch({ type: types.CREATE_CATEGORY, payload: data.categories });
    notification.success({
      message: `${category.name} category added`,
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(formSubmitted(true));
    dispatch({ type: types.CATEGORY_ERROR, payload: error.message });
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const updateCategory = (
  category: EditCategoryFormValues
): ThunkAction<{}, AppState, null, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  try {
    const { data } = await api.put(
      '/admindashboard/therapy/category',
      category
    );
    dispatch(formSubmitted(true));
    dispatch({ type: types.UPDATE_CATEGORY, payload: data.categories });
    notification.success({
      message: `${category.name} category Updated`,
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(formSubmitted(true));
    dispatch({ type: types.CATEGORY_ERROR, payload: error.message });
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const setSelectedCategory = (category: Category) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({ type: types.SELECTED_CATEGORY, payload: category });
};

export const removeAvatar = (): ThunkAction<void, AppState, {}, AnyAction> => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>,
  getState
) => {
  const { therapy } = getState();

  dispatch({
    type: types.REMOVE_AVATAR_FROM_CATEGORY,
    payload: { ...therapy.selectedCategory, avatar: null },
  });
};

export const createSubCategory = (
  subCategory: SubCategory
): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  try {
    const { data } = await api.post(
      '/admindashboard/therapy/subcategory',
      subCategory
    );
    notification.success({
      message: `${subCategory.name} subCategory added`,
      placement: 'bottomRight',
    });
    dispatch(formSubmitted(true));
    dispatch({ type: types.CREATE_SUBCATEGORY, payload: data.subCategories });
  } catch (e) {
    const error = axiosErrorHandler(e);
    notification.error({ message: error.message, placement: 'bottomRight' });
    dispatch(formSubmitted(true));
    dispatch({ type: types.SUBCATEGORY_ERROR, payload: error.message });
  }
};

export const updateSubCategory = (
  subCategory: SubCategory
): ThunkAction<{}, AppState, null, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  try {
    const { data } = await api.put(
      '/admindashboard/therapy/subcategory',
      subCategory
    );
    dispatch(formSubmitted(true));
    dispatch({ type: types.UPDATE_SUBCATEGORY, payload: data.subCategories });
    notification.success({
      message: `${subCategory.name} subcategory Updated`,
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(formSubmitted(true));
    dispatch({ type: types.SUBCATEGORY_ERROR, payload: error.message });
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const setSelectedSubCategory = (subCategory: SubCategory) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({ type: types.SELECTED_SUBCATEGORY, payload: subCategory });
};

export const removeAvatarFromSubCategory = (): ThunkAction<
  void,
  AppState,
  {},
  AnyAction
> => (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState) => {
  const { therapy } = getState();

  dispatch({
    type: types.REMOVE_AVATAR_FROM_SUBCATEGORY,
    payload: { ...therapy.selectedSubCategory, avatar: null },
  });
};

export const createCatalogue = (
  catalogue: Catalogue
): ThunkAction<void, AppState, {}, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    const { data } = await api.post(
      '/admindashboard/therapy/catalogue',
      catalogue
    );
    dispatch(formSubmitted(true));
    dispatch({ type: types.CREATE_CATALOGUE, payload: data.catalogues });
    notification.success({
      message: 'Catalogue created',
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch({ type: types.CATALOGUE_ERROR, payload: error.message });
    dispatch(formSubmitted(true));
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const getCatalogue = () => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    dispatch(loading(true));
    const { data } = await api.get('/admindashboard/therapy/catalogue');
    dispatch(loading(false));
    dispatch({ type: types.FETCH_CATALOGUE, payload: data.catalogues });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(loading(false));
    dispatch({ type: types.CATALOGUE_ERROR, payload: error.message });
  }
};

export const getSubCategories = () => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    dispatch(loading(false));
    const { data } = await api.get('/admindashboard/therapy/subcategory');
    dispatch(loading(true));
    dispatch({ type: types.FETCH_SUBCATEGORIES, payload: data.subCategories });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch({ type: types.FETCH_SUBCATEGORIES_ERROR, payload: error.message });
    dispatch(loading(false));
  }
};

export const updateCatalogue = (
  catalogue: Catalogue
): ThunkAction<void, AppState, {}, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    const { data } = await api.put(
      '/admindashboard/therapy/catalogue',
      catalogue
    );
    dispatch(formSubmitted(true));
    console.log(data);
    dispatch({ type: types.UPDATE_CATALOGUE, payload: data.catalogues });
    notification.success({
      message: 'Catalogue Updated',
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch({ type: types.CATALOGUE_ERROR, payload: error.message });
    dispatch(formSubmitted(true));
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const deleteCatalogue = (
  id: string
): ThunkAction<void, AppState, {}, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    const { data } = await api.put(`/admindashboard/therapy/catalogue/${id}`);
    console.log(data);
    dispatch({ type: types.UPDATE_CATALOGUE, payload: data.catalogues });
    notification.success({
      message: 'Catalogue Updated',
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch({ type: types.CATALOGUE_ERROR, payload: error.message });
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const deleteCategory = (id: string) => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    const { data } = await api.put(`/admindashboard/therapy/category/${id}`);
    dispatch({ type: types.UPDATE_CATEGORY, payload: data.categories });
    notification.success({
      message: 'Deleted Successfully',
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch({ type: types.CATEGORY_ERROR, payload: error.message });
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const deleteSubCategory = (id: string) => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    const { data } = await api.put(`/admindashboard/therapy/subcategory/${id}`);
    console.log(data);
    dispatch({ type: types.UPDATE_SUBCATEGORY, payload: data.subCategories });
    notification.success({
      message: 'Deleted Successfully',
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch({ type: types.UPDATE_SUBCATEGORY, payload: error.message });
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

const therapyCenterLoader = (payload) => ({
  type: types.THERAPY_CENTER_LOADER,
  payload,
});

export const createTherapyCenter = (
  therapyCenterData: any
): ThunkAction<void, AppState, {}, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    console.log(therapyCenterData);
    dispatch(therapyCenterLoader(true));
    await api.post(`/admindashboard/therapycenter`, therapyCenterData);
    dispatch(therapyCenterLoader(false));
    dispatch({ type: types.CREATE_THERAPY_CENTER, payload: true });
    notification.success({
      message: `${therapyCenterData.name} created successfully`,
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(therapyCenterLoader(false));
    dispatch({ type: types.CREATE_THERAPY_CENTER, payload: false });
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};
export const updateTherapyCenter = (
  therapyCenterData: any,
  canGoBack: boolean = true
): ThunkAction<void, AppState, {}, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  try {
    console.log(therapyCenterData);
    dispatch(therapyCenterLoader(true));
    await api.put(`/admindashboard/therapycenter`, therapyCenterData);
    dispatch(therapyCenterLoader(false));
    if (canGoBack) {
      history.goBack();
    }
    notification.success({
      message: `${therapyCenterData.profileData.name} updated successfully`,
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(therapyCenterLoader(false));
    dispatch({ type: types.CREATE_THERAPY_CENTER, payload: false });
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const addTherapyUser = (userProfileData: any) => async (dispatch) => {
  try {
    dispatch(therapyCenterLoader(true));
    await api.put('/admindashboard/userprofile', userProfileData);
    dispatch(therapyCenterLoader(false));
    notification.success({
      message: `User profile created`,
      placement: 'bottomRight',
    });
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(therapyCenterLoader(false));
    notification.error({
      message: error.message,
      placement: 'bottomRight',
    });
  }
};

export const fetchTherapyCenters = ({ search, online, isActive }) => async (
  dispatch
) => {
  try {
    dispatch(loading(true));
    console.log(search);
    const { data } = await api.get(
      `/admindashboard/therapycenters?search=${search}&online=${online}&isActive=${isActive}`
    );
    console.log(data);
    dispatch({
      type: types.FETCH_THERAPY_CENTERS,
      payload: data.therapyCenters,
    });
    dispatch(loading(true));
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(loading(true));
    dispatch({
      type: types.FETCH_THERAPY_CENTERS_ERROR,
      payload: error,
    });
  }
};

export const fetchTherapyUserProfile = () => async (dispatch) => {
  try {
    dispatch(loading(true));
    const { data } = await api.get('/admindashboard/therapy/userprofile');
    console.log(data);
    dispatch({
      type: types.FETCH_THERAPY_USER_PROFILE,
      payload: data.userProfiles,
    });
    dispatch(loading(true));
  } catch (e) {
    const error = axiosErrorHandler(e);
    dispatch(loading(true));
    dispatch({
      type: types.FETCH_THERAPY_USER_PROFILE_ERROR,
      payload: error,
    });
  }
};
