import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from '../reducers';

type Compose = typeof compose;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: Compose;
  }
}

/**
 *  Redux dev tool enhancer
 */
const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 *  Middleware
 */
const logger = createLogger({ collapsed: true });
const middleware = [thunk, logger];

/**
 *  Store creation
 */
const store = createStore(
  rootReducer,
  enhancer(applyMiddleware(...middleware))
);

export default store;
