import { Breadcrumb, Icon, Layout, Table, Tag } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';

import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { AppState } from '../../redux/reducers';
import { Props } from './therapyUserProfile';

class TherapyUserProfile extends PureComponent<Props> {
  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (role: []) => role.map((r) => <Tag key={r}>{r}</Tag>),
    },
    {
      title: 'Edit',
      dataIndex: 'Action',
      render: (_: any, record: any) => (
        <Icon
          type="edit"
          theme="outlined"
          onClick={() => this.navigateToEditProfile(record)}
        />
      ),
    },
  ];

  navigateToEditProfile = (record) => {
    const { history } = this.props;
    history.push('/therapy/add_therapy_users', { record, isEdit: true });
  };

  navigateToAddProfile = () => {
    const { history } = this.props;
    history.push('/therapy/add_therapy_users');
  };

  componentDidMount() {
    this.props.fetchTherapyUserProfile();
  }

  render() {
    const { userProfiles } = this.props;
    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Therapy</Breadcrumb.Item>
          <Breadcrumb.Item>User Profile</Breadcrumb.Item>
        </StyledBreadcrumb>

        <StyledLayout>
          <Table
            dataSource={userProfiles}
            columns={this.columns}
            rowKey={(record: any) => record.orderId}
            bordered={false}
            size="small"
          />
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.therapy.isLoading,
  userProfiles: state.therapy.userProfiles,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TherapyUserProfile);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
`;

const ToolBar = styled.div`
  margin: 24px 0 24px 0;
`;
const LinkText = styled.p`
  cursor: pointer;
  text-decoration: underline;
  color: #3366bb;
`;

const StyledP = styled.p`
  text-transform: capitalize;
  vertical-align: center;
  margin: 0;
`;

const Italic = styled.i`
  text-transform: capitalize;
  color: #757575;
  font-size: 12px;
`;
