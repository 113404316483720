import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { Props } from './vouchers';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { Breadcrumb, Row, Button, Table, Layout } from 'antd';
import styled from 'styled-components';

class Vouchers extends PureComponent<Props> {
  columns: Array<any> = [
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Voucher',
      dataIndex: 'coupon',
      key: 'coupon',
      render: (_: any, record: any) => {
        return record.coupon.code;
      }
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => {
        return <p>{isActive ? 'Active' : 'Expired'}</p>;
      }
    },
    {
      title: 'Redeem Status',
      dataIndex: 'isRedeem',
      key: 'isRedeem',
      render: (isRedeem: boolean) => {
        return <p>{!isRedeem ? 'Not Redeemed' : 'Redeemed'}</p>;
      }
    }
  ];

  componentDidMount() {
    const { fetchAllVouchers } = this.props;
    fetchAllVouchers();
  }

  render() {
    const { vouchers } = this.props;
    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Coupon</Breadcrumb.Item>
          <Breadcrumb.Item>Vouchers</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledLayout>
          <Table
            size="small"
            columns={this.columns}
            dataSource={vouchers}
            bordered
          />
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vouchers: state.coupon.vouchers
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vouchers);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
  min-height: 80vh;
`;
