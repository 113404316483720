import React from 'react';
import styled from 'styled-components';
import { Cascader } from 'antd';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { MedSysProps } from './medSysPicker';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';

const MedSysPicker: React.FC<MedSysProps> = props => {
  const { setMedicalSystem, selectedTreatment } = props;

  const options = [
    { value: 'Ayurveda', label: 'Ayurveda' },
    { value: 'Siddha', label: 'Siddha' },
    { value: 'Homeopathy', label: 'Homeopathy' }
  ];

  const onChange = (value: any) => {
    setMedicalSystem(value);
  };

  return (
    <MedSysWrapper>
      <Cascader
        options={options}
        onChange={value => onChange(value)}
        placeholder="Select Medical System"
        defaultValue={selectedTreatment}
      />
    </MedSysWrapper>
  );
};

export const mapStateToProps = (state: AppState) => ({
  selectedTreatment: state.doctorMetrics.selectedTreatment
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MedSysPicker);

const MedSysWrapper = styled.div`
  padding-bottom: 20px;
`;
