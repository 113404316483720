import './App.css';
import './styles/appTailwind.css';

import React, { Component } from 'react';
import { Provider } from 'react-redux';

import store from './redux/store';
import AppRouter from './route/AppRouter';

interface Props {}

class App extends Component<Props> {
  render() {
    return (
      <Provider store={store}>
        <AppRouter />
      </Provider>
    );
  }
}

export default App;
