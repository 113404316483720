import React, { useContext } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { AuthContext } from '../components/auth';
import Aside from '../components/sideDrawer';
import AppBar from '../components/appBar';
import './style.css';
import { PrimaryLayoutProps } from './layout';

const { Content } = Layout;
const PrimaryLayout: React.FC<PrimaryLayoutProps> = props => {
  const { children } = props;
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <>{children}</>;
  }

  return (
    <StyledLayout>
      <AppBar />
      <Layout>
        <Aside />
        <StyledContent>{children}</StyledContent>
      </Layout>
    </StyledLayout>
  );
};

export default PrimaryLayout;

const StyledLayout = styled(Layout)`
  height: 100vh;
`;

const StyledContent = styled(Content)`
  margin: 56px 8px 8px;
`;
