import { Switch, TimePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

import { IHours } from '../../addTherapyCentre';

interface ISlotPopOverContent {
  buttonName: string;
  index: number;
  hIndex?: number;
  hour?: IHours;
  onAddSlot?(hours: IHours[], index: number): void;
  onEditSlot?(hour: IHours, index: number, hIndex: number): void;
  setVisible(visible: boolean): void;
}

const SlotPopOverContent = ({
  buttonName,
  onAddSlot,
  onEditSlot,
  index,
  hIndex,
  setVisible,
  hour,
}: ISlotPopOverContent) => {
  const initFrom = hour ? moment(hour.from, 'hh:mm A') : moment();
  const initTo = hour ? moment(hour.to, 'hh:mm A') : moment();
  const initActive = hour ? hour.isActive : true;
  const [from, setFrom] = useState(initFrom);
  const [to, setTo] = useState(initTo);
  const [active, setActive] = useState<boolean>(initActive);

  const onButtonClick = () => {
    if (buttonName === 'Create') {
      const hour: IHours = {
        from: moment(from).format('HH:mm'),
        to: moment(to).format('HH:mm a'),
        isActive: active,
        start: Number(`${moment(from).day()}${moment(from).format('hhmm')}`),
        end: Number(`${moment(to).day()}${moment(to).format('hhmm')}`),
      };
      onAddSlot([hour], index);
      setVisible(false);
    } else {
      const hour: IHours = {
        from: moment(from).format('HH:mm'),
        to: moment(to).format('HH:mm'),
        isActive: active,
        start: Number(`${moment(from).day()}${moment(from).format('hhmm')}`),
        end: Number(`${moment(to).day()}${moment(to).format('hhmm')}`),
      };
      onEditSlot(hour, index, hIndex);
      setVisible(false);
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-around items-center">
        <TimePicker
          format="h:mm a"
          placeholder="From"
          value={from}
          onChange={(time) => setFrom(time)}
        />
        <div className="px-1" />
        <TimePicker
          format="h:mm a"
          placeholder="To"
          value={to}
          onChange={(time) => setTo(time)}
        />
      </div>
      <div className="flex flex-row  pt-1">
        <p className="text-black mb-0 pr-1">Active</p>
        <div>
          <Switch
            size="small"
            checked={active}
            onChange={(checked) => setActive(checked)}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <div
          onClick={onButtonClick}
          className="cursor-pointer px-2 bg-primary text-white rounded-full"
        >
          <p className="m-0">{buttonName}</p>
        </div>
      </div>
    </div>
  );
};

export default SlotPopOverContent;
