import { Icon } from 'antd';
import Upload from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import * as firebase from 'firebase';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';

import { actionCreators } from '../../../../redux/actions/ActionCreators';
import { AppState } from '../../../../redux/reducers';
import { AddCategoryFormValues, Props } from './addCategoryModalContent';

const AddCategoryModelContent: React.FC<
  Props & FormikProps<AddCategoryFormValues>
> = (props) => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = props;

  const onUpload = async (uploadObj: any) => {
    try {
      const { file } = uploadObj;
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      const uploadTask = uploadRef.child(path).put(file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadObj.onProgress({ percent: progress });
        },
        (error: any) => {
          uploadObj.onError(error.code);
          setFieldValue('avatar', '');
        },
        async () => {
          const imgUrl = await uploadTask.snapshot.ref.getDownloadURL();
          uploadObj.file.thumbUrl = imgUrl;
          uploadObj.onSuccess({ imgUrl });
          setFieldValue('avatar', imgUrl);
        }
      );
    } catch (error) {
      console.log(error);
      setFieldValue('avatar', '');
    }
  };

  const onRemove = async (file: UploadFile) => {
    try {
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      uploadRef.child(path).delete();
      setFieldValue('avatar', '');
    } catch (error) {
      console.log(error);
      setFieldValue('avatar', '');
    }
  };

  return (
    <div>
      <p className="text-lg font-medium text-center">Add Category</p>
      <form
        className="flex flex-col justify-center px-20 py-4"
        onSubmit={handleSubmit}
      >
        <section className="mb-4">
          <p className="block text-grey-700 text-base font-bold pr-2 mb-2">
            Category Name
          </p>
          <input
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Ex: Pain Management"
          />
          {errors.name && touched.name && (
            <span className="mt-3 text-red-500 pl-2">{errors.name}</span>
          )}
        </section>
        <section className="mb-4">
          <p className="block text-grey-700 text-base font-bold pr-2 mb-2">
            Category Avatar
          </p>
          <Upload.Dragger
            customRequest={(option) => onUpload(option)}
            onRemove={onRemove}
            multiple={false}
            name="files"
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
          {errors.avatar && touched.avatar && (
            <span className="mt-3 text-red-500 pl-2">{errors.avatar}</span>
          )}
        </section>
        <div className="flex justify-center">
          <button
            type="submit"
            className="w-20 bg-primary text-white focus:outline-none focus:shadow-none font-bold py-2 px-4 rounded-full"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

const EnhancedForm = withFormik<Props, AddCategoryFormValues>({
  mapPropsToValues: (props) => ({
    name: '',
    avatar: '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name required'),
    avatar: Yup.string().required('Avatar required'),
  }),
  handleSubmit: (value, { props, resetForm }) => {
    props.createCategory(value);
    if (props.formSubmitted) {
      resetForm();
      return props.onCancel();
    }
  },
})(AddCategoryModelContent);

const mapStateToProps = (state: AppState) => ({
  formSubmitted: state.therapy.formSubmitted,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);
