import React, { PureComponent } from 'react';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import {
  Form,
  Select,
  InputNumber,
  Switch,
  Button,
  Upload,
  Icon,
  Row,
  Col,
  Input,
  DatePicker,
  Breadcrumb,
  notification
} from 'antd';
import styled from 'styled-components';
import * as firebase from 'firebase/app';
import moment from 'moment';

import { Props, CouponForm, State } from './createCoupon';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { AnyAction, bindActionCreators, compose } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { ThunkDispatch } from 'redux-thunk';
import { FormComponentProps } from 'antd/lib/form/Form';
import { convertToPhoneArray } from '../../utils/helpers';

const { Option } = Select;

let id = 1;
class CreateCoupon extends PureComponent<Props & FormComponentProps, State> {
  state: State = {
    submitLoading: false
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { form, createCoupon, fetchAllCoupons } = this.props;

    form.validateFields(async (err, values) => {
      try {
        if (!err) {
          this.setState({ submitLoading: true });
          const {
            banner,
            terms,
            startUtcDate,
            endUtcDate,
            keys,
            ...rest
          } = values;
          const couponData = {
            ...rest,
            terms: values.terms.filter((entry: any) => {
              return entry.trim() != '';
            }),
            phone: convertToPhoneArray(values.phone),
            banner: banner && banner[0].response.imgUrl,
            startUtcDate: moment(startUtcDate)
              .utc()
              .startOf('day')
              .format(),
            endUtcDate: moment(endUtcDate)
              .utc()
              .endOf('day')
              .format()
          };

          const { message, status } = await createCoupon(couponData);
          if (status === 201) {
            form.resetFields();
            fetchAllCoupons();
            notification.open({
              message,
              icon: <Icon type="smile" style={{ color: '#1DA57A' }} />
            });
          }
          this.setState({ submitLoading: false });
        }
      } catch (error) {
        const { message } = error;
        this.setState({ submitLoading: false });
        notification.open({
          message: error.message ? message : 'Please try after sometimes',
          icon: <Icon type="cry" style={{ color: 'tomato' }} />
        });
      }
    });
  };

  normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e && e.fileList;
  };

  onUpload = async (uploadObj: any) => {
    try {
      const { file } = uploadObj;
      const path = `banner/${file.name}`;
      const uploadRef = firebase.storage().ref();
      const uploadTask = uploadRef.child(path).put(file);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadObj.onProgress({ percent: progress });
        },
        (error: any) => {
          uploadObj.onError(error.code);
        },
        async () => {
          const imgUrl = await uploadTask.snapshot.ref.getDownloadURL();
          uploadObj.file.thumbUrl = imgUrl;
          uploadObj.onSuccess({ imgUrl });
        }
      );
    } catch (error) {
      // console.log(error);
    }
  };

  voucherGenHandler = async () => {
    try {
      const { generateVoucher, form } = this.props;
      const code = await generateVoucher();
      form.setFieldsValue({
        code: code
      });
    } catch (error) {
      // console.log(error);
    }
  };

  remove = (k: number) => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const terms = form.getFieldValue('terms');

    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter((key: number) => {
        return key !== k;
      })
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  render() {
    const { form } = this.props;
    const { submitLoading } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const mode = getFieldValue('mode');
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 6 }
    };

    getFieldDecorator('keys', {
      initialValue: [0],
      rules: [{ required: true, message: 'Terms and Condition required' }]
    });

    const keys = getFieldValue('keys');

    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Create Coupon</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledLayout>
          <Row type="flex" justify="center">
            <Col span={24} offset={6}>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Col span={24} offset={6}>
                  <StyledH1>Create Coupon</StyledH1>
                </Col>

                <Form.Item label="Name" hasFeedback>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Coupon name required' }]
                  })(<Input placeholder="Coupon name" />)}
                </Form.Item>

                <Form.Item label="Type" hasFeedback>
                  {getFieldDecorator('type', {
                    rules: [
                      { required: true, message: 'Coupon type cannot be empty' }
                    ]
                  })(
                    <Select placeholder="Select coupon type">
                      <Option value="flat">Flat</Option>
                      <Option value="percentage">Percentage</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Coupon Constraint" hasFeedback>
                  {getFieldDecorator('planConstraint', {
                    rules: [
                      {
                        required: true,
                        message: 'Select coupon constraint',
                        type: 'array'
                      }
                    ]
                  })(
                    <Select
                      mode="multiple"
                      placeholder="Select one or more constraint"
                    >
                      <Option value="consultation">Consultation</Option>
                      <Option value="medicine order">Medicine Order</Option>
                      <Option value="products">Product</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Mode" hasFeedback>
                  {getFieldDecorator('mode', {
                    rules: [
                      { required: true, message: 'Coupon mode cannot be empty' }
                    ]
                  })(
                    <Select placeholder="Select coupon mode">
                      <Option value="voucher">Voucher</Option>
                      <Option value="coupon">Coupon</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Medical System" hasFeedback>
                  {getFieldDecorator('medSys', {
                    initialValue: ['ayurveda', 'siddha', 'homeopathy'],
                    rules: [
                      {
                        required: true,
                        message: 'Currency code cannot be empty'
                      }
                    ]
                  })(
                    <Select mode="multiple" placeholder="Select Medical System">
                      <Option value="ayurveda">Ayurveda</Option>
                      <Option value="siddha">Siddha</Option>
                      <Option value="homeopathy">Homeopathy</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Currency Code" hasFeedback>
                  {getFieldDecorator('currencyCode', {
                    rules: [
                      {
                        required: true,
                        message: 'Currency code cannot be empty'
                      }
                    ]
                  })(
                    <Select placeholder="Select currency type">
                      <Option value="INR">INR</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Coupon Code">
                  <Row gutter={8}>
                    <Col span={14}>
                      {getFieldDecorator('code', {
                        rules: [
                          { required: true, message: 'Coupon Code required' }
                        ]
                      })(
                        <Input
                          disabled={mode === 'voucher'}
                          placeholder="Coupon Code"
                        />
                      )}
                    </Col>
                    <Col span={8}>
                      {mode === 'voucher' ? (
                        <Button
                          style={{ display: 'inline-block' }}
                          type="primary"
                          onClick={this.voucherGenHandler}
                        >
                          Auto Generate
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item label="Start Date" hasFeedback>
                  {getFieldDecorator('startUtcDate', {
                    rules: [
                      { required: true, message: 'Start date cannot be empty' }
                    ]
                  })(<DatePicker placeholder="Start Date" />)}
                </Form.Item>

                <Form.Item label="End Date" hasFeedback>
                  {getFieldDecorator('endUtcDate', {
                    rules: [
                      { required: true, message: 'End date cannot be empty' }
                    ]
                  })(<DatePicker placeholder="End Date" />)}
                </Form.Item>

                <Form.Item label="Maximum Redeem">
                  {getFieldDecorator('maxRedeem', {
                    initialValue: 100,
                    rules: [
                      {
                        required: true,
                        message: 'Maximum redeem cannot be empty'
                      }
                    ]
                  })(<InputNumber min={1} />)}
                </Form.Item>

                <Form.Item label="Maximum Redeem Per Person">
                  {getFieldDecorator('maxRedeemPerUser', {
                    initialValue: 1,
                    rules: [
                      {
                        required: true,
                        message: 'Maximum redeem per person cannot be empty'
                      }
                    ]
                  })(<InputNumber min={1} />)}
                </Form.Item>

                <Form.Item label="Discount(₹)">
                  {getFieldDecorator('discount', {
                    rules: [
                      {
                        required: true,
                        message: 'Discount cannot be empty'
                      }
                    ]
                  })(<InputNumber />)}
                </Form.Item>

                <Form.Item label="Maximum Discount(₹)">
                  {getFieldDecorator('maxDiscount', {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        message: 'Maximum discount cannot be empty'
                      }
                    ]
                  })(<InputNumber />)}
                </Form.Item>

                <Form.Item label="Coupon Status">
                  {getFieldDecorator('isActive', {
                    valuePropName: 'checked',
                    initialValue: true
                  })(<Switch />)}
                </Form.Item>

                <Form.Item label="Terms & Conditions">
                  {keys.map((k: number, index: number) => (
                    <Form.Item required={false} key={k}>
                      <Row type="flex" gutter={4}>
                        <Col span={20}>
                          {getFieldDecorator(`terms[${k}]`, {
                            validateTrigger: ['onChange', 'onBlur'],
                            initialValue: '',
                            rules: [
                              {
                                required: false,
                                message: 'Terms & Conditions required'
                              }
                            ]
                          })(<Input placeholder="Add Terms and Condition" />)}
                        </Col>
                        <Col span={2}>
                          {keys.length > 1 ? (
                            <Icon
                              type="minus-circle-o"
                              onClick={() => this.remove(k)}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Button type="dashed" onClick={this.add}>
                    <Icon type="plus" /> Add
                  </Button>
                </Form.Item>

                <Form.Item label="Description">
                  {getFieldDecorator('description')(
                    <Input placeholder="Add Description about coupon" />
                  )}
                </Form.Item>

                <Form.Item label="Mobile Number/s">
                  {getFieldDecorator('phone')(
                    <Input.TextArea placeholder="Add Description about coupon" />
                  )}
                </Form.Item>

                <Form.Item label="Banner">
                  {getFieldDecorator('banner', {
                    valuePropName: 'fileList',
                    getValueFromEvent: this.normFile
                    // rules: [
                    //   { required: true, message: 'Banner image is required' }
                    // ]
                  })(
                    <Upload.Dragger
                      customRequest={option => this.onUpload(option)}
                      multiple={false}
                      name="files"
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                      </p>
                    </Upload.Dragger>
                  )}
                </Form.Item>

                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Button
                    loading={submitLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  code: state.coupon.code
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create<CouponForm & FormComponentProps>({
    name: 'CreateCoupon'
  })
)(CreateCoupon as any);

const StyledLayout = styled.div`
  background: #fff;
  border-radius: 5px;
`;

const StyledH1 = styled.h1`
  font-size: 20px;
  margin: 16px 0;
`;
