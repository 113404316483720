import { Icon, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import * as firebase from 'firebase';
import React from 'react';

interface Props {
  setAvatar: Function;
  showError: boolean | undefined;
  errMsg: string | undefined;
  toggleModal: Function;
}

export const TherapyAvatarModal: React.FC<Props> = (props: Props) => {
  const { setAvatar, showError, toggleModal, errMsg } = props;

  const onUpload = async (uploadObj: any) => {
    try {
      const { file } = uploadObj;
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      const uploadTask = uploadRef.child(path).put(file);
      let imgUrl = await uploadRef.child(path).getDownloadURL();
      if (!imgUrl) {
        uploadTask.on(
          'state_changed',
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            uploadObj.onProgress({ percent: progress });
          },
          (error: any) => {
            uploadObj.onError(error.code);
            setAvatar('');
          },
          async () => {
            const imgUrl = await uploadTask.snapshot.ref.getDownloadURL();
            uploadObj.file.thumbUrl = imgUrl;
            uploadObj.onSuccess({ imgUrl });
            setAvatar(imgUrl);
          }
        );
      } else {
        uploadObj.onSuccess({ imgUrl });
        setAvatar(imgUrl);
      }
    } catch (error) {
      console.log(error);
      setAvatar('');
    }
  };

  const onRemove = async (file: UploadFile) => {
    try {
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      uploadRef.child(path).delete();
      setAvatar('');
    } catch (error) {
      console.log(error);
      setAvatar('');
    }
  };

  return (
    <div>
      <p className="block text-grey-700 text-center text-base font-bold pr-2 mb-2">
        Upload Avatar
      </p>
      <Upload.Dragger
        customRequest={onUpload}
        onRemove={onRemove}
        multiple={false}
        name="files"
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Upload.Dragger>
      {showError && <span className="mt-3 text-red-500 pl-2">{errMsg}</span>}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => toggleModal()}
          className="bg-primary text-white font-bold py-2 px-4 rounded-full"
        >
          Submit
        </button>
      </div>
    </div>
  );
};
