import './style.css';

import { CloseCircleFilled } from '@ant-design/icons';
import { Breadcrumb, Modal, Popconfirm } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Loader from '../../components/loader';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { Category, SubCategory } from '../../redux/actions/therapyAction/datatypes';
import { AppState } from '../../redux/reducers';
import { Props, State } from './categories';
import AddCategoryModelContent from './components/addCategoryModelContent';
import AddSubCategoryModelContent from './components/addSubCategoryModalContent';
import EditCategoryModelContent from './components/editCategoryModelContent';
import EditSubCategoryModalContent from './components/editSubCategoryModalContent';

class Categories extends PureComponent<Props, State> {
  state: State = {
    createCategoryModal: false,
    createSubCategoryModal: false,
    editCategoryModal: false,
    editSubCategoryModal: false,
  };

  componentDidMount() {
    this.props.getCategoriesAndSubCategories();
  }

  toggleCreateCategoryModal = () => {
    this.setState((prevState) => ({
      createCategoryModal: !prevState.createCategoryModal,
    }));
  };

  toggleEditCategoryModal = () => {
    this.setState((prevState) => ({
      editCategoryModal: !prevState.editCategoryModal,
    }));
  };

  toggleCreateSubCategoryModal = () => {
    this.setState((prevState) => ({
      createSubCategoryModal: !prevState.createSubCategoryModal,
    }));
  };

  toggleEditSubCategoryModal = () => {
    this.setState((prevState) => ({
      editSubCategoryModal: !prevState.editSubCategoryModal,
    }));
  };

  setSelectedCategory = (category: Category) => {
    this.props.setSelectedCategory(category);
    this.toggleEditCategoryModal();
  };

  setSelectedSubCategory = (subCategory: SubCategory) => {
    this.props.setSelectedSubCategory(subCategory);
    this.toggleEditSubCategoryModal();
  };

  render() {
    const {
      createCategoryModal,
      createSubCategoryModal,
      editCategoryModal,
      editSubCategoryModal,
    } = this.state;
    const {
      isLoading,
      categories,
      subCategories,
      categoryHasError,
      categoryErrorMsg,
      subCategoryHasError,
      subCategoryErrorMsg,
    } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    if (categoryHasError && subCategoryHasError) {
      return (
        <div className="w-full flex justify-center items-center">
          <p>{categoryHasError}</p>
        </div>
      );
    }

    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Therapy</Breadcrumb.Item>
          <Breadcrumb.Item>Categories</Breadcrumb.Item>
        </StyledBreadcrumb>
        <section className="w-full flex justify-end">
          <button
            onClick={this.toggleCreateCategoryModal}
            className="flex justify-center items-center bg-primary hover:bg-white text-white hover:text-primary ease-in duration-75 focus:outline-none focus:shadow-none font-bold py-2 px-4 rounded-full mr-4"
          >
            Add Category
          </button>
          <button
            onClick={this.toggleCreateSubCategoryModal}
            className="flex justify-center items-center bg-primary hover:bg-white text-white hover:text-primary ease-in duration-75 focus:outline-none focus:shadow-none font-bold py-2 px-4 rounded-full"
          >
            Add Sub-Category
          </button>
        </section>
        <section className="px-8">
          <p className="text-lg font-bold">Categories</p>
          {categoryHasError ? (
            <div className="flex justify-center items-center">
              <p className="text:base">{categoryErrorMsg}</p>
            </div>
          ) : (
            <div className="flex flex-wrap flex-grow justify-evenly">
              {categories.length >= 1 ? (
                categories.map((category) => (
                  <div
                    key={category.name}
                    onClick={() => this.setSelectedCategory(category)}
                    className="w-full category-hover sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mx-1 mb-4 bg-white rounded-lg flex items-center py-2 px-4 cursor-pointer relative"
                  >
                    <img
                      src={category.avatar}
                      className="w-10 h-10 rounded-full mr-4"
                      alt={category.name}
                    />
                    <h3>{category.name}</h3>
                    <div className="absolute show-delete right-0">
                      <Popconfirm
                        title="Are you sure delete?"
                        onConfirm={(e) => {
                          e.stopPropagation();
                          this.props.deleteCategory(category._id);
                        }}
                        onCancel={(e) => {
                          e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <CloseCircleFilled
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-base text-gray-800 font-semibold">
                  No Categories found
                </p>
              )}
            </div>
          )}
        </section>
        <section className="px-8">
          <p className="text-lg font-bold">Sub-Categories</p>
          {subCategoryHasError ? (
            <p>{subCategoryErrorMsg}</p>
          ) : (
            <div className="flex flex-wrap flex-grow justify-evenly">
              {subCategories.length >= 1 ? (
                subCategories.map((subCategory: SubCategory) => (
                  <div
                    key={subCategory.name}
                    onClick={() => this.setSelectedSubCategory(subCategory)}
                    className="w-full relative sub-category-hover sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mx-1 mb-4 bg-white rounded-lg flex items-center py-2 px-4 cursor-pointer"
                  >
                    <img
                      src={subCategory.avatar}
                      className="w-10 h-10 rounded-full mr-4"
                      alt={subCategory.name}
                    />
                    <h3>{subCategory.name}</h3>
                    <div className="absolute show-delete right-0">
                      <Popconfirm
                        title="Are you sure delete?"
                        onConfirm={(e) => {
                          e.stopPropagation();
                          this.props.deleteSubCategory(subCategory._id);
                        }}
                        onCancel={(e) => {
                          e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <CloseCircleFilled
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-base text-gray-800 font-semibold">
                  No Categories found
                </p>
              )}
            </div>
          )}
        </section>
        <Modal
          visible={createCategoryModal}
          footer={null}
          onCancel={this.toggleCreateCategoryModal}
          destroyOnClose
        >
          <AddCategoryModelContent onCancel={this.toggleCreateCategoryModal} />
        </Modal>
        <Modal
          visible={createSubCategoryModal}
          footer={null}
          onCancel={this.toggleCreateSubCategoryModal}
          destroyOnClose
        >
          <AddSubCategoryModelContent
            onCancel={this.toggleCreateSubCategoryModal}
          />
        </Modal>
        <Modal
          visible={editCategoryModal}
          footer={null}
          onCancel={this.toggleEditCategoryModal}
          destroyOnClose
        >
          <EditCategoryModelContent onCancel={this.toggleEditCategoryModal} />
        </Modal>
        <Modal
          visible={editSubCategoryModal}
          footer={null}
          onCancel={this.toggleEditSubCategoryModal}
          destroyOnClose
        >
          <EditSubCategoryModalContent
            onCancel={this.toggleEditSubCategoryModal}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProp = (state: AppState) => {
  const {
    isLoading,
    categories,
    subCategories,
    categoryHasError,
    subCategoryHasError,
    categoryErrorMsg,
    subCategoryErrorMsg,
  } = state.therapy;
  return {
    isLoading,
    categories,
    categoryHasError,
    categoryErrorMsg,
    subCategories,
    subCategoryHasError,
    subCategoryErrorMsg,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProp, mapDispatchToProps)(Categories);
