import { UIState, LoadingActionType } from '../actions/uiAction/datatypes';
import * as types from '../types';

const initialState: UIState = {
  loader: false,
  mode: 'dev'
};

const uiReducer = (state = initialState, action: LoadingActionType) => {
  switch (action.type) {
    case types.START_LOADER: {
      const { payload } = action;
      return {
        ...state,
        loader: payload.loader
      };
    }
    case types.STOP_LOADER: {
      const { payload } = action;
      return {
        ...state,
        loader: payload.loader
      };
    }
    case types.SET_ENV_MODE: {
      const { mode } = action;
      return {
        ...state,
        mode
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
