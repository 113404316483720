import React from 'react';
import styled from 'styled-components';
import { Cascader } from 'antd';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { OrderTypeProps } from './orderType';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';

const OrderTypePicker: React.FC<OrderTypeProps> = props => {
  const {
    orderType,
    setOrderType,
    selectedOrderType,
    getMedOrderMetricsData
  } = props;

  const onChange = (value: any) => {
    setOrderType(value);
  };

  return (
    <MedSysWrapper>
      <Cascader
        options={orderType}
        onChange={value => onChange(value)}
        placeholder="Select Order Type"
        defaultValue={selectedOrderType}
      />
    </MedSysWrapper>
  );
};

export const mapStateToProps = (state: AppState) => ({
  orderType: state.doctorMetrics.orderType,
  selectedOrderType: state.doctorMetrics.selectedOrderType
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

//@ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(OrderTypePicker);

const MedSysWrapper = styled.div`
  padding-bottom: 20px;
`;
