import { Breadcrumb, Cascader, Col, Icon, Input, Layout, Row, Switch, Table, Tag } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';

import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { AppState } from '../../redux/reducers';
import { DebouncedSearch } from './components/debouncedSearch';
import { Props } from './therapyCentre';

const { Search } = Input;

class TherapyCentres extends PureComponent<Props> {
  statusOptions = [
    { value: '0', label: 'Active' },
    { value: '1', label: 'In-Active' },
  ];

  availableOptions = [
    { value: '0', label: 'Online' },
    { value: '1', label: 'Offline' },
  ];

  state = {
    status: [this.statusOptions[0].value],
    availablle: [this.availableOptions[0].value],
    search: '',
  };

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name: string, record: any) => (
        <div>
          <StyledP>{name}</StyledP>
          <Italic>{record.phone.mobile}</Italic>
        </div>
      ),
    },
    {
      title: 'Therapist',
      dataIndex: 'therapist',
      render: (therapist: []) => <p>{therapist.length}</p>,
    },
    {
      title: 'Services',
      dataIndex: 'services',
      render: (services: any) =>
        services.map((service) => <Tag key={service.name}>{service.name}</Tag>),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      render: (isActive: boolean, record: any) => (
        <Switch
          defaultChecked={isActive}
          onChange={(e) => this.onActiveChange(e, record)}
        />
      ),
    },
    {
      title: 'Online',
      dataIndex: 'online',
      render: (online: boolean, record: any) => (
        <Switch
          defaultChecked={online}
          onChange={(e) => this.onOnlineChange(e, record)}
        />
      ),
    },
    {
      title: 'Edit',
      dataIndex: 'Action',
      render: (_: any, record: any) => (
        <Icon
          type="edit"
          theme="outlined"
          onClick={() => this.navigateToEditProfile(record)}
        />
      ),
    },
  ];

  onActiveChange = (e: boolean, record: any) => {
    this.props.updateTherapyCenter(
      {
        profileId: record._id,
        profileData: { ...record, isActive: e },
      },
      false
    );
  };

  onOnlineChange = (e: boolean, record: any) => {
    this.props.updateTherapyCenter(
      {
        profileId: record._id,
        profileData: { ...record, online: e },
      },
      false
    );
  };

  navigateToEditProfile = (record) => {
    const { history } = this.props;
    history.push('/therapy/add_therapy_centres', { record, isEdit: true });
  };

  navigateToAddProfile = () => {
    const { history } = this.props;
    history.push('/therapy/add_therapy_centres');
  };

  onStatusChange = (value: string[]) => {
    this.props.fetchTherapyCenters({
      search: '',
      isActive: value[0],
      online: '0',
    });
  };

  onAvailableChange = (value: string[]) => {
    this.props.fetchTherapyCenters({
      search: '',
      online: value[0],
      isActive: '0',
    });
  };

  componentDidMount() {
    this.props.fetchTherapyCenters({
      search: '',
      online: true,
      isActive: true,
    });
  }

  render() {
    const { status, availablle } = this.state;
    const { therapyCenters, isLoading } = this.props;

    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Therapy</Breadcrumb.Item>
          <Breadcrumb.Item>Therapy Centres</Breadcrumb.Item>
        </StyledBreadcrumb>

        <StyledLayout>
          <ToolBar>
            <Row type="flex" justify="space-around">
              <Col span={8}>
                <DebouncedSearch />
              </Col>
              <Col span={3}>
                <Cascader
                  options={this.statusOptions}
                  onChange={this.onStatusChange}
                  placeholder="Status"
                  defaultValue={status}
                />
              </Col>
              <Col span={3}>
                <Cascader
                  options={this.availableOptions}
                  onChange={this.onAvailableChange}
                  placeholder="Available"
                  defaultValue={availablle}
                />
              </Col>
            </Row>
          </ToolBar>
          <Table
            dataSource={therapyCenters}
            columns={this.columns}
            rowKey={(record: any) => record.orderId}
            bordered={false}
            size="small"
          />
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.therapy.isLoading,
  therapyCenters: state.therapy.therapyCenters,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TherapyCentres);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
`;

const ToolBar = styled.div`
  margin: 24px 0 24px 0;
`;
const LinkText = styled.p`
  cursor: pointer;
  text-decoration: underline;
  color: #3366bb;
`;

const StyledP = styled.p`
  text-transform: capitalize;
  vertical-align: center;
  margin: 0;
`;

const Italic = styled.i`
  text-transform: capitalize;
  color: #757575;
  font-size: 12px;
`;
