import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { AuthProvider } from '../components/auth';
import LoginView from '../components/login';
import PrimaryLayout from '../layouts/primaryLayout';
import { AppState } from '../redux/reducers';
import Four0Four from '../screens/404';
import AddTherapyCentre from '../screens/addTherapyCentre';
import AddTherapyUser from '../screens/addTherapyUser';
import Catalogues from '../screens/catalogues';
import Categories from '../screens/categories';
import ConsultationMetric from '../screens/consultationMetrics';
import Coupon from '../screens/coupon';
import CouponRecords from '../screens/couponRecords';
import CreateCoupon from '../screens/createCoupon';
import CreateProduct from '../screens/createProduct';
import CreateProductCategories from '../screens/createProductCategory';
import Dashboard from '../screens/dashboard';
import EditCoupon from '../screens/editCoupon';
import MedOrderMetrics from '../screens/medOrderMetrics';
import ProductCategories from '../screens/productCategory';
import Products from '../screens/products';
import TherapyCentres from '../screens/therapyCentre';
import TherapyUserProfiles from '../screens/therapyUserProfiles';
import Vouchers from '../screens/vouchers';
import PrivateRoute from './privateRoute';

interface Props {}

const AppRouter: React.FC<Props> = (props) => {
  return (
    <AuthProvider>
      <Router>
        <PrimaryLayout>
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute
              exact
              path="/metrics/consultation"
              component={ConsultationMetric}
            />
            <PrivateRoute
              exact
              path="/metrics/medorder"
              component={MedOrderMetrics}
            />
            <PrivateRoute exact path="/coupon" component={Coupon} />
            <PrivateRoute exact path="/createcoupon" component={CreateCoupon} />
            <PrivateRoute exact path="/editcoupon" component={EditCoupon} />
            <PrivateRoute exact path="/vouchers" component={Vouchers} />
            <PrivateRoute exact path="/products" component={Products} />
            <PrivateRoute
              exact
              path="/createproduct"
              component={CreateProduct}
            />
            <PrivateRoute
              exact
              path="/productcategories"
              component={ProductCategories}
            />
            <PrivateRoute
              exact
              path="/createproductcategories"
              component={CreateProductCategories}
            />
            <PrivateRoute
              exact
              path="/couponRecords"
              component={CouponRecords}
            />
            <PrivateRoute
              exact
              path="/therapy/therapy_centres"
              component={TherapyCentres}
            />
            <PrivateRoute
              exact
              path="/therapy/therapy_user_profile"
              component={TherapyUserProfiles}
            />
            <PrivateRoute
              exact
              path="/therapy/add_therapy_users"
              component={AddTherapyUser}
            />
            <PrivateRoute
              exact
              path="/therapy/add_therapy_centres"
              component={AddTherapyCentre}
            />
            <PrivateRoute
              exact
              path="/therapy/catalogues"
              component={Catalogues}
            />
            <PrivateRoute
              exact
              path="/therapy/categories"
              component={Categories}
            />
            <Route exact path="/login" component={LoginView} />
            <Route component={Four0Four} />
          </Switch>
        </PrimaryLayout>
      </Router>
    </AuthProvider>
  );
};

const mapStateToProps = (state: AppState) => ({ mode: state.ui.mode });

export default connect(mapStateToProps)(AppRouter);
