import { Breadcrumb, Button, Icon, Layout, Modal, Row, Table, Tag } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, bindActionCreators, compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';

import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { AppState } from '../../redux/reducers';
import { Props, State } from './coupon';

class Coupons extends Component<Props, State> {
  state: State = {
    modal: false,
    record: {},
  };

  columns: Array<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name: string, record: any) => (
        <div>
          <StyledP>{name}</StyledP>
          <Italic>
            {record.mode}
            <span>
              <Italic> - ({record.type})</Italic>
            </span>
          </Italic>
        </div>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      render: (code: string, record: any) => (
        <LinkText onClick={() => this.onCouponClick(record)}>{code}</LinkText>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive: boolean) => {
        return isActive ? (
          <ActiveText>Active</ActiveText>
        ) : (
          <InActiveText>In Active</InActiveText>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'startUtcDate',
      render: (startUtcDate: string) => (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{moment(startUtcDate).format('DD/MM/YYYY')}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>
              {moment(startUtcDate).format('hh:mm A')}
            </i>
          </div>
        </>
      ),
    },
    {
      title: 'Expires At',
      dataIndex: 'endUtcDate',
      render: (endUtcDate: string) => (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{moment(endUtcDate).format('DD/MM/YYYY')}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>
              {moment(endUtcDate).format('hh:mm A')}
            </i>
          </div>
        </>
      ),
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      render: (discount: string) => {
        if (discount) return <StyledP>₹{discount}</StyledP>;
        return <div />;
      },
    },
    {
      title: 'Constraints',
      dataIndex: 'planConstraint',
      render: (planConstraint: Array<string>) =>
        planConstraint.map((c) => <Tag key={c}>{c.toUpperCase()}</Tag>),
    },
    {
      title: 'Max Redeem',
      dataIndex: 'maxRedeem',
      render: (maxRedeem: string) => <StyledP>{maxRedeem}</StyledP>,
    },
    {
      title: 'MRPU',
      dataIndex: 'maxRedeemPerUser',
      render: (maxRedeemPerUser: string) => (
        <StyledP>{maxRedeemPerUser}</StyledP>
      ),
    },
    {
      title: 'Redeem Count',
      dataIndex: 'redeemedCount',
      render: (redeemedCount: string) => <StyledP>{redeemedCount}</StyledP>,
    },
    {
      title: '',
      dataIndex: 'Action',
      render: (_: any, record: any) => (
        <Icon
          type="edit"
          theme="outlined"
          onClick={() => this.editCoupon(record)}
        />
      ),
    },
  ];

  editCoupon = (record: any) => {
    const { history } = this.props;
    history.push({
      pathname: '/editcoupon',
      state: { editData: record },
    });
  };

  componentDidMount() {
    const { fetchAllCoupons } = this.props;
    fetchAllCoupons();
  }

  componentDidUpdate(prevProps: any) {
    const { mode } = this.props;
    const { mode: prevMode } = prevProps;
    // console.log(`Prev Props mode ${prevMode} - current mode ${mode}`);
    if (prevMode !== mode) {
      const { fetchAllCoupons } = this.props;
      fetchAllCoupons();
    }
  }

  couponCreateHandler = () => {
    const { history } = this.props;
    history.push('/createcoupon');
  };

  toggleModal = () =>
    this.setState((prevState) => ({ modal: !prevState.modal }));

  onCouponClick = (record: any) => {
    this.setState({
      modal: true,
      record,
    });
  };

  render() {
    const { coupons } = this.props;
    const { record } = this.state;
    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Coupon</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledLayout>
          <Row type="flex" justify="end" align="middle">
            <CouponBtnWrapper>
              <Button onClick={this.couponCreateHandler} type="primary">
                Create Coupon
              </Button>
            </CouponBtnWrapper>
          </Row>
          <Table
            rowKey={(record: any) => record.code}
            size="small"
            columns={this.columns}
            dataSource={coupons}
          />
        </StyledLayout>
        <Modal
          title="Terms & Conditions"
          visible={this.state.modal}
          onCancel={this.toggleModal}
          footer={null}
        >
          {this.state.modal ? (
            <>
              <Row type="flex" justify="center">
                {record && record.banner && (
                  <img
                    src={record.banner}
                    alt="banner"
                    style={{ width: 320, height: 80, objectFit: 'contain' }}
                  />
                )}
              </Row>
              <ul>
                {record.terms.map((point: any) => (
                  <li key={moment().format('x')}>{point}</li>
                ))}
              </ul>
            </>
          ) : null}
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  coupons: state.coupon.coupons,
  mode: state.ui.mode || 'dev',
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Coupons);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
  min-height: 80vh;
`;

const CouponBtnWrapper = styled.div`
  padding: 20px;
`;

// const Banner = styled.img`
//   border-radius: 15px;
//   background: #acacac;
//   width: 180px;
//   height: 80px;
// `;

const Captitalize = styled.p`
  text-transform: capitalize;
  vertical-align: center;
  margin: 0;
`;

const StyledP = styled.p`
  text-transform: capitalize;
  vertical-align: center;
  margin: 0;
`;

const LinkText = styled.p`
  cursor: pointer;
  text-decoration: underline;
  color: #3366bb;
`;

const ActiveText = styled.p`
  color: #00cc99;
`;

const InActiveText = styled.p`
  color: #ff7979;
`;

const Italic = styled.i`
  text-transform: capitalize;
  color: #757575;
  font-size: 12px;
`;
