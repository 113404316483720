import React, { useEffect, useState } from 'react';

import firebase from '../../firebaseConfig';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setLoading(false);
        setCurrentUser(user);
      }
      setLoading(false);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
