import './style.css';

import { Breadcrumb, Icon, Modal, Select } from 'antd';
import { FormikProps, withFormik } from 'formik';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'underscore';
import * as Yup from 'yup';

import Avatar from '../../assets/avatar.png';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { AppState } from '../../redux/reducers';
import { TherapyAvatarModal } from '../addTherapyCentre/components/avatarModal';
import Label from '../addTherapyCentre/components/label';
import { AddTherapyUserFormValues, Props, State } from './addTherapyUser';

const gender = ['Male', 'Female', 'Other'];

const roles = [
  { label: 'Therapist', value: 'therapist' },
  { label: 'Therapy Admin', value: 'therapy_admin' },
  { label: 'Manager', value: 'manager' },
];

const languages = [
  'Tamil',
  'English',
  'Hindi',
  'Malayalam',
  'Telugu',
  'Kannadam',
];

const qualification = ['BAMS', 'MD'];

class AddTherapyUser extends PureComponent<
  Props & FormikProps<AddTherapyUserFormValues>,
  State
  > {
  state: State = {
    avatarModal: false,
  };

  componentDidMount() {
    const { fetchTherapyCenters } = this.props;
    fetchTherapyCenters({ search: '', online: '0', isActive: '0' });
  }

  toggleAvatarModal = () => {
    this.setState((prevState) => ({
      avatarModal: !prevState.avatarModal,
    }));
  };

  render() {
    const {
      handleChange,
      handleBlur,
      handleSubmit,
      values,
      errors,
      touched,
      therapyCenters,
      setFieldValue,
    } = this.props;
    console.log(errors);
    return (
      <div>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Therapy</Breadcrumb.Item>
          <Breadcrumb.Item>Add Therapy Users</Breadcrumb.Item>
        </StyledBreadcrumb>
        <h3 className="flex justify-center text-xl text-bold text-gray-700 mt-4">
          Add Therapy User
        </h3>
        <Modal
          visible={this.state.avatarModal}
          footer={null}
          onCancel={this.toggleAvatarModal}
        >
          <TherapyAvatarModal
            showError={touched.avatar && errors.avatar !== undefined}
            errMsg={errors.avatar}
            toggleModal={this.toggleAvatarModal}
            setAvatar={(avatar: string) => setFieldValue('avatar', avatar)}
          />
        </Modal>
        <form className="w-full px-24" onSubmit={handleSubmit}>
          <div className="bg-white px-16 py-8">
            <section className="flex">
              <div className="w-24 h-24 border-grey border-2 rounded-full mr-12 relative">
                <img
                  className="rounded-full"
                  src={values.avatar}
                  alt={values.name}
                />
                <div
                  onClick={this.toggleAvatarModal}
                  className="absolute cursor-pointer bottom-0 right-0 bg-white border-gray-300 border-2 w-8 h-8 flex justify-center items-center rounded-full"
                >
                  <Icon type="edit" />
                </div>
              </div>
              <div className="w-2/3 my-4">
                <Label name="Name" required />
                <input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: John Doe"
                />
                {errors.name && touched.name && (
                  <span className="mt-3 text-red-500 pl-2">{errors.name}</span>
                )}
              </div>
            </section>
            <div className="flex w-full my-4">
              <div className="w-1/3 pr-4">
                <Label name="Phone Number" required />
                <input
                  name="phone"
                  type="tel"
                  maxLength={13}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: +9193602XXXXX"
                />
                {errors.phone && touched.phone && (
                  <span className="mt-3 text-red-500 pl-2">{errors.phone}</span>
                )}
              </div>
              <div className="w-1/3 pr-4">
                <Label name="Email Address" required />
                <input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: johndoe@natcue.com"
                />
                {errors.email && touched.email && (
                  <span className="mt-3 text-red-500 pl-2">{errors.email}</span>
                )}
              </div>
              <div className="w-1/3 pr-4">
                <Label name="Gender" required />
                <div className="relative">
                  <Select
                    id="language-select"
                    placeholder="Select Gender"
                    onChange={handleChange('gender')}
                    showArrow
                    value={values.gender}
                  >
                    {_.map(gender, (g) => (
                      <Select.Option key={g} value={g}>
                        {g}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.gender && touched.gender && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.gender}
                  </span>
                )}
              </div>
            </div>
            <div className="w-full flex my-4">
              <div className="w-1/3 pr-4">
                <Label name="Role" required />
                <div className="relative">
                  <Select
                    id="language-select"
                    placeholder="Select Role"
                    onChange={handleChange('role')}
                    showArrow
                    mode="multiple"
                    maxTagCount={5}
                    maxTagTextLength={6}
                    defaultValue={values.role}
                  >
                    {_.map(roles, (role) => (
                      <Select.Option key={role.value} value={role.value}>
                        {role.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.role && touched.role && (
                  <span className="mt-3 text-red-500 pl-2">{errors.role}</span>
                )}
              </div>
              <div className="w-1/3 pr-4">
                <Label name="Qualification" required />
                <div className="relative">
                  <Select
                    id="language-select"
                    placeholder="Select Qualification"
                    onChange={handleChange('qualification')}
                    showArrow
                    mode="multiple"
                    maxTagCount={5}
                    maxTagTextLength={6}
                    defaultValue={values.qualification}
                  >
                    {_.map(qualification, (q) => (
                      <Select.Option key={q} value={q}>
                        {q}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.qualification && touched.qualification && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.qualification}
                  </span>
                )}
              </div>
              <div className="w-1/3 pr-4">
                <Label name="Language" required />
                <div className="relative">
                  <Select
                    id="language-select"
                    placeholder="Select Languages"
                    onChange={handleChange('languages')}
                    showArrow
                    mode="multiple"
                    maxTagCount={5}
                    maxTagTextLength={6}
                    defaultValue={values.languages}
                    style={{ minHeight: 90 }}
                  >
                    {_.map(languages, (language) => (
                      <Select.Option key={language} value={language}>
                        {language}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.languages && touched.languages && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.languages}
                  </span>
                )}
              </div>
            </div>
            <div className="w-full flex my-4">
              <div className="w-1/3 pr-4">
                <Label name="Therapists" required />
                <div className="relative">
                  <Select
                    id="language-select"
                    placeholder="Select Therapists"
                    onChange={handleChange('therapist')}
                    showArrow
                    mode="multiple"
                    maxTagCount={5}
                    maxTagTextLength={6}
                    disabled={!values.role.includes('therapist')}
                    defaultValue={values.therapist}
                  >
                    {_.map(therapyCenters, (therapist: any) => (
                      <Select.Option key={therapist._id} value={therapist._id}>
                        {therapist.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.therapist && touched.therapist && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.therapist}
                  </span>
                )}
              </div>
              <div className="w-1/3 pr-4">
                <Label name="Admin" required />
                <div className="relative">
                  <Select
                    id="language-select"
                    placeholder="Select Admin"
                    onChange={handleChange('therapy_admin')}
                    showArrow
                    mode="multiple"
                    maxTagCount={5}
                    maxTagTextLength={6}
                    disabled={!values.role.includes('therapy_admin')}
                    defaultValue={values.therapy_admin}
                  >
                    {_.map(therapyCenters, (admin: any) => (
                      <Select.Option key={admin._id} value={admin._id}>
                        {admin.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.therapy_admin && touched.therapy_admin && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.therapy_admin}
                  </span>
                )}
              </div>
              <div className="w-1/3 pr-4">
                <Label name="Managers" required />
                <div className="relative">
                  <Select
                    id="language-select"
                    placeholder="Select Managers"
                    onChange={handleChange('manager')}
                    showArrow
                    mode="multiple"
                    maxTagCount={5}
                    maxTagTextLength={6}
                    disabled={!values.role.includes('manager')}
                    defaultValue={values.manager}
                  >
                    {_.map(therapyCenters, (manager: any) => (
                      <Select.Option key={manager._id} value={manager._id}>
                        {manager.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.manager && touched.manager && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.manager}
                  </span>
                )}
              </div>
            </div>
            <button
              className="bg-primary text-white font-bold py-2 px-4 rounded-full mt-4"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const EnhancedForm = withFormik<Props, AddTherapyUserFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const isEdit = props.history.location.state?.isEdit;
    const record = props.history.location.state?.record;
    return {
      phone: record?.name || '',
      avatar: record?.avatar || Avatar,
      email: record?.email || '',
      gender: record?.gender || '',
      isActive: record?.isActive ? true : false,
      name: record?.name || '',
      role: record?.role || [],
      languages: record?.languages || [],
      qualification: record?.qualification || [],
      registrationNo: record?.registrationNo || '',
      therapist: record?.therapyCentres?.therapist || [],
      therapy_admin: record?.therapyCentres?.therapy_admin || [],
      manager: record?.therapyCentres?.manager || [],
    };
  },
  handleSubmit: (values: AddTherapyUserFormValues, { props }) => {
    const isEdit = props.history.location.state?.isEdit;
    const record = props.history.location.state?.record;
    const { therapist, therapy_admin, manager, ...rest } = values;
    const profileData = {
      therapyCentres: { therapist, therapy_admin, manager },
      ...rest,
    };
    if (isEdit) {
      return;
    }
    return props.addTherapyUser(profileData);
  },
  validationSchema: Yup.object().shape({
    phone: Yup.string().required('Phone required'),
    avatar: Yup.string().required('Avatar required'),
    email: Yup.string().email().required('Email required'),
    gender: Yup.string().required('Gender required'),
    isActive: Yup.boolean().required('Active required'),
    name: Yup.string().required('Name required'),
    role: Yup.array().of(Yup.string()).required('Role required'),
    languages: Yup.array().of(Yup.string()).required('Languages required'),
    qualification: Yup.array()
      .of(Yup.string())
      .required('Qualification required'),
    // registrationNo: Yup.string().required('registrationNo required'),
  }),
})(AddTherapyUser);

const mapStateToProps = (state: AppState) => ({
  therapyCenters: state.therapy.therapyCenters,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);
