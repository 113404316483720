import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { AnyAction } from 'redux';
import { api } from '../../../utils/api';
import { DateData, getMetricsParams } from './datatypes';
import * as types from '../../types';

export const getConsultationMetricsData = (
  params: getMetricsParams
): ThunkAction<void, AppState, {}, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>,
  getState
) => {
  try {
    dispatch({
      type: types.DOWNLOAD_CSV_SUCCESS,
      payload: []
    });

    dispatch({
      type: types.START_LOADER,
      payload: {
        loader: true
      }
    });

    const { page, pageSize, sortOrder = null } = params;

    const {
      selectedDoctor,
      selectedIllness,
      selectedTreatment,
      selectedRangePicker,
      selectedStatus
    } = getState().doctorMetrics;

    let url = `/admindashboard/metrics?fromdate=${selectedRangePicker.fromDate}&todate=${selectedRangePicker.toDate}&page=${page}&pageSize=${pageSize}`;

    if (selectedDoctor.length > 0) {
      url = url + `&doctor=${selectedDoctor[0]}`;
    }

    if (selectedTreatment.length > 0)
      url = url + `&medSys=${selectedTreatment[0]}`;

    if (selectedIllness.length > 0)
      url = url + `&illness=${selectedIllness[0]}`;

    if (selectedStatus.length > 0) url = url + `&status=${selectedStatus[0]}`;

    if (sortOrder) url = url + `&sort=${sortOrder}`;

    const { data } = await api.get(url);

    dispatch({
      type: types.GET_METRICS_DATA_SUCCESS,
      payload: data
    });

    setTimeout(() => {
      dispatch({
        type: types.STOP_LOADER,
        payload: {
          loader: false
        }
      });
    }, 800);
  } catch (error) {
    // console.log(error);
    setTimeout(() => {
      dispatch({
        type: types.STOP_LOADER,
        payload: {
          loader: false
        }
      });
    }, 120);
  }
};

export const downloadCSV = (): ThunkAction<
  void,
  AppState,
  {},
  AnyAction
> => async (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState) => {
  try {
    dispatch({
      type: types.DOWNLOAD_CSV_SUCCESS,
      payload: []
    });

    const {
      selectedDoctor,
      selectedIllness,
      selectedTreatment,
      selectedRangePicker,
      selectedStatus
    } = getState().doctorMetrics;

    let url = `/admindashboard/getcsv?fromdate=${selectedRangePicker.fromDate}&todate=${selectedRangePicker.toDate}`;

    if (selectedDoctor.length > 0) {
      url = url + `&doctor=${selectedDoctor[0]}`;
    }

    if (selectedTreatment.length > 0)
      url = url + `&medSys=${selectedTreatment[0]}`;

    if (selectedIllness.length > 0)
      url = url + `&illness=${selectedIllness[0]}`;

    if (selectedStatus.length > 0) url = url + `&status=${selectedStatus[0]}`;

    const { data } = await api.get(url);

    dispatch({
      type: types.DOWNLOAD_CSV_SUCCESS,
      payload: data.csv
    });
  } catch (error) {
    // console.log(error);
    dispatch({
      type: types.DOWNLOAD_CSV_FAILURE,
      payload: []
    });
  }
};

export const setRangePicker = (dateData: DateData) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.SET_RANGE_PICKER,
    payload: dateData
  });
};

export const setMedicalSystem = (medSys: Array<string>) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.SET_MED_SYS,
    payload: medSys
  });
};

export const setIllness = (illness: Array<string>) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.SET_ILLNESS,
    payload: illness
  });
};

export const setDoctor = (doctor: Array<string>) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.SET_DOCTOR,
    payload: doctor
  });
};

export const setStatus = (status: Array<string>) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.SET_STATUS,
    payload: status
  });
};

export const setOrderType = (orderType: Array<string>) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.SET_ORDER_TYPE,
    payload: orderType
  });
};

export const getMedOrderMetricsData = (
  params: getMetricsParams & { orderType?: string }
): ThunkAction<void, AppState, {}, AnyAction> => async (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>,
  getState
) => {
  try {
    dispatch({
      type: types.START_LOADER,
      payload: {
        loader: true
      }
    });
    const { page, pageSize, sortOrder } = params;
    const {
      selectedOrderType,
      selectedTreatment,
      selectedRangePicker,
      selectedMedOrderStatus
    } = getState().doctorMetrics;

    let { orderType = selectedOrderType[0] } = params;

    let url = `/admindashboard/metrics/medorder?fromdate=${selectedRangePicker.fromDate}&todate=${selectedRangePicker.toDate}&page=${page}&pageSize=${pageSize}&orderType=${orderType}`;

    if (selectedTreatment.length > 0)
      url = url + `&medSys=${selectedTreatment[0]}`;

    if (selectedMedOrderStatus.length > 0)
      url = url + `&status=${selectedMedOrderStatus[0]}`;

    if (sortOrder) url = url + `&sort=${sortOrder}`;

    const { data } = await api.get(url);

    dispatch({
      type: types.GET_MED_ORDER_METRICS,
      payload: data
    });
    setTimeout(() => {
      dispatch({
        type: types.STOP_LOADER,
        payload: {
          loader: false
        }
      });
    }, 800);
  } catch (error) {
    // TODO error
    setTimeout(() => {
      dispatch({
        type: types.STOP_LOADER,
        payload: {
          loader: false
        }
      });
    }, 800);
  }
};

export const setMedOrderStatus = (status: Array<string>) => (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => {
  dispatch({
    type: types.SET_MED_ORDER_STATUS,
    payload: status
  });
};
