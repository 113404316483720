import React, { useCallback } from 'react';
import { Avatar, Menu, Icon, Layout, Select } from 'antd';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducers';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { MetricsModel } from '../../redux/actions/metricsAction/datatypes';

import firebase from '../../firebaseConfig';
import UserAvatar from '../../assets/avatar.png';
import { CACHE_KEYS } from '../../utils/constant';

const firebaseAuth = firebase.auth();
const { SubMenu } = Menu;
const { Header } = Layout;
const { Option } = Select;

const AppBar: React.FC<any> = props => {
  const offlineSelectedMode = localStorage.getItem(CACHE_KEYS.MODE) || 'dev';
  const signoutHandle = useCallback(e => {
    // console.log('User logged out!!!!!');
    localStorage.removeItem(CACHE_KEYS.MODE);
    firebaseAuth.signOut();
  }, []);
  const { setMode } = props;

  const modeHandler = useCallback(
    v => {
      // console.log('Selected -- value ', v);
      localStorage.setItem(CACHE_KEYS.MODE, v);
      setMode(v);
    },
    [setMode]
  );

  return (
    <StyledHeader theme="dark">
      <Menu
        theme="dark"
        mode="horizontal"
        selectable={false}
        style={{ float: 'right' }}
      >
        <Menu.Item key="0">
          <Select
            defaultValue={offlineSelectedMode}
            style={{ width: 120 }}
            onSelect={modeHandler}
          >
            <Option value="dev">Dev</Option>
            <Option value="prod">Live</Option>
          </Select>
        </Menu.Item>
        <SubMenu key="sub1" title={<Icon type="bell" />}>
          <Menu.Item key="1">Completed</Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" title={<Avatar src={UserAvatar} />}>
          <Menu.Item key="2" onClick={signoutHandle}>
            Sign Out
          </Menu.Item>
        </SubMenu>
      </Menu>
    </StyledHeader>
  );
};

const mapStateToProps = (state: AppState) => ({
  mode: state.ui.mode
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppBar)
);

const StyledHeader = styled(Header)`
  position: fixed;
  height: 48px;
  line-height: 48px;
  background: #001529;
  padding: 0;
  z-index: 1;
  width: 100%;
  display: block;
  box-shadow: none;
`;
