import { CloseCircleFilled } from '@ant-design/icons';
import { Icon, Popconfirm, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import * as firebase from 'firebase';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';

import { actionCreators } from '../../../../redux/actions/ActionCreators';
import { AppState } from '../../../../redux/reducers';
import { EditCategoryFormValues, Props } from './editCategoryModalContent';

const EditCategoryModelContent: React.FC<
  Props & FormikProps<EditCategoryFormValues>
> = (props) => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = props;

  console.log(values);

  const onUpload = async (uploadObj: any) => {
    try {
      const { file } = uploadObj;
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      const uploadTask = uploadRef.child(path).put(file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadObj.onProgress({ percent: progress });
        },
        (error: any) => {
          uploadObj.onError(error.code);
          setFieldValue('avatar', '');
        },
        async () => {
          const imgUrl = await uploadTask.snapshot.ref.getDownloadURL();
          uploadObj.file.thumbUrl = imgUrl;
          uploadObj.onSuccess({ imgUrl });
          setFieldValue('avatar', imgUrl);
        }
      );
    } catch (error) {
      console.log(error);
      setFieldValue('avatar', '');
    }
  };

  const onRemove = async (file: UploadFile) => {
    try {
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      uploadRef.child(path).delete();
      setFieldValue('avatar', '');
    } catch (error) {
      console.log(error);
      setFieldValue('avatar', '');
    }
  };

  return (
    <div>
      <p className="text-lg font-medium text-center">Edit Category</p>
      <form
        className="flex flex-col justify-center px-20 py-4"
        onSubmit={handleSubmit}
      >
        <section className="mb-4">
          <p className="block text-grey-700 text-base font-bold pr-2 mb-2">
            Category Name
          </p>
          <input
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Ex: Pain Management"
          />
          {errors.name && touched.name && (
            <span className="mt-3 text-red-500 pl-2">{errors.name}</span>
          )}
        </section>
        <section className="mb-4">
          <p className="block text-grey-700 text-base font-bold pr-2 mb-2">
            Category Avatar
          </p>
          {values.avatar !== null && values.avatar !== '' ? (
            <div className="flex">
              <div className="relative">
                <img
                  className="w-20 h-20 rounded-full items-center"
                  src={values.avatar}
                  alt={values.name}
                />
                <div className="absolute top-0 right-0 cursor-pointer">
                  <Popconfirm
                    title="Do you want yo delete？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={props.removeAvatar}
                  >
                    <CloseCircleFilled />
                  </Popconfirm>
                </div>
              </div>
            </div>
          ) : (
            <Upload.Dragger
              customRequest={(option) => onUpload(option)}
              onRemove={onRemove}
              multiple={false}
              name="files"
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
          )}

          {errors.avatar && touched.avatar && (
            <span className="mt-3 text-red-500 pl-2">{errors.avatar}</span>
          )}
        </section>
        <div className="flex justify-center">
          <button
            type="submit"
            className="w-20 bg-primary text-white focus:outline-none focus:shadow-none font-bold py-2 px-4 rounded-full"
          >
            Edit
          </button>
        </div>
      </form>
    </div>
  );
};

const EnhancedForm = withFormik<Props, EditCategoryFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    if (props.category) {
      return {
        // @ts-ignore
        id: props.category._id || '',
        name: props.category.name || '',
        avatar: props.category.avatar || '',
      };
    } else {
      return {
        id: '',
        name: '',
        avatar: '',
      };
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name required'),
    avatar: Yup.string().required('Avatar required'),
  }),
  handleSubmit: (value, { props, resetForm }) => {
    // @ts-ignore
    props.updateCategory({
      id: value.id,
      name: value.name,
      avatar: value.avatar,
    });
    if (props.formSubmitted) {
      return props.onCancel();
    }
  },
})(EditCategoryModelContent);

const mapStateToProps = (state: AppState) => ({
  formSubmitted: state.therapy.formSubmitted,
  category: state.therapy.selectedCategory,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);
