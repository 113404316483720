import React from 'react';
import styled from 'styled-components';
import { Cascader } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { DrProps } from './drPicker';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';

const DrPicker: React.FC<DrProps> = props => {
  const { doctors, setDoctor, selectedDoctor } = props;

  const onChange = (value: any) => {
    setDoctor(value);
  };

  return (
    <MedSysWrapper>
      <Cascader
        options={doctors}
        onChange={value => onChange(value)}
        placeholder="Select Doctor"
        defaultValue={selectedDoctor}
      />
    </MedSysWrapper>
  );
};

export const mapStateToProps = (state: AppState) => ({
  doctors: state.doctorMetrics.doctors,
  selectedDoctor: state.doctorMetrics.selectedDoctor
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrPicker);

const MedSysWrapper = styled.div`
  padding-bottom: 20px;
`;
