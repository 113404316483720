import { Checkbox, Radio } from 'antd';
import CheckboxGroup, { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormikProps, withFormik } from 'formik';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { actionCreators } from '../../../../redux/actions/ActionCreators';
import { SubCategory } from '../../../../redux/actions/therapyAction/datatypes';
import { AppState } from '../../../../redux/reducers';
import { EditCatalogueFormValues, Props, State } from './editCatalogue';

class EditCatalogue extends PureComponent<
  Props & FormikProps<EditCatalogueFormValues>,
  State
> {
  componentDidMount() {
    this.props.getSubCategories();
  }

  onSubCategorySelected = (values: CheckboxValueType[]) => {
    const { setFieldValue, allSubCategories } = this.props;
    const selectedSubCategories = allSubCategories
      .filter((subCategory) => values.includes(subCategory.name))
      .map((subCategory) => subCategory);
    setFieldValue('subCategories', selectedSubCategories);
  };

  render() {
    const { handleSubmit, values } = this.props;
    const selected = values.subCategories.map((s) => s.name);
    return (
      <div className="w-full">
        <form onSubmit={handleSubmit}>
          <h3 className="flex justify-center text-xl text-bold text-gray-700 mt-4">
            Edit Catalogue
          </h3>
          <div className="bg-white py-4">
            <p className="text-base font-semibold text-gray-700 flex justify-center">
              Selected Category
            </p>
            <section className="flex justify-evenly">
              <Radio.Group value={this.props.catalogue.name}>
                <Radio.Button value={this.props.catalogue.name}>
                  {this.props.catalogue.name}
                </Radio.Button>
              </Radio.Group>
            </section>
            <p className="text-base font-semibold text-gray-700 flex justify-center py-4">
              Edit Sub-Category
            </p>
            <section>
              <CheckboxGroup
                onChange={this.onSubCategorySelected}
                defaultValue={selected}
              >
                <div className="flex px-12 flex-wrap mb-4">
                  {this.props.allSubCategories.map(
                    (subCategory: SubCategory) => (
                      <div className="w-1/5 h-12">
                        <Checkbox
                          key={subCategory.name}
                          value={subCategory.name}
                        >
                          {subCategory.name}
                        </Checkbox>
                      </div>
                    )
                  )}
                </div>
              </CheckboxGroup>
            </section>
            <div className="flex justify-end">
              <button
                className="flex justify-center items-center bg-primary text-white focus:outline-none focus:shadow-none font-bold py-2 px-4 rounded-full mr-4"
                type="submit"
              >
                Edit Catalogue
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const EnhancedForm = withFormik<Props, EditCatalogueFormValues>({
  mapPropsToValues: (values) => {
    return {
      category: values.catalogue,
      subCategories: values.catalogue.subCategory,
    };
  },
  handleSubmit: (values, { props, resetForm }) => {
    const updatedCatalogue = {
      id: values.category._id,
      name: values.category.name,
      avatar: values.category.avatar,
      subCategory: values.subCategories,
    };
    props.updateCatalogue(updatedCatalogue);
    if (props.formSubmitted) {
      resetForm();
      props.onCancel();
    }
  },
})(EditCatalogue);

const mapStateToProps = (state: AppState) => ({
  formSubmitted: state.therapy.formSubmitted,
  isLoading: state.therapy.isLoading,
  allSubCategories: state.therapy.subCategories,
});

const mapDispatchToProp = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProp)(EnhancedForm);
