import React, { PureComponent } from 'react';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import {
  Form,
  Select,
  InputNumber,
  Switch,
  Button,
  Upload,
  Icon,
  Row,
  Col,
  Input,
  DatePicker,
  Breadcrumb,
  notification
} from 'antd';
import styled from 'styled-components';
import * as firebase from 'firebase/app';
import moment from 'moment';

import { Props, CouponForm, State } from './editCoupon';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { AnyAction, bindActionCreators, compose } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { ThunkDispatch } from 'redux-thunk';
import { FormComponentProps } from 'antd/lib/form/Form';
import { withRouter } from 'react-router';
import { convertToPhoneArray } from '../../utils/helpers';

const { Option } = Select;

class EditCoupon extends PureComponent<Props & FormComponentProps, State> {
  state: State = {
    submitLoading: false,
    prevView:
      this.props.location.state && this.props.location.state.editData
        ? this.props.location.state.editData.banner
        : null
  };

  id: number = this.props.location.state.editData.terms.length.toString();

  // componentDidUpdate(prevProps: any, prevState: any) {
  //   Object.entries(this.props).forEach(
  //     ([key, val]) =>
  //       prevProps[key] !== val && console.log(`Prop '${key}' changed`)
  //   );
  //   if (this.state) {
  //     Object.entries(this.state).forEach(
  //       ([key, val]) =>
  //         prevState[key] !== val && console.log(`State '${key}' changed`)
  //     );
  //   }
  // }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { form, editCoupon, history, location, fetchAllCoupons } = this.props;

    let editData: any = null;
    if (location && location.state && location.state.editData) {
      editData = location.state.editData;
    }

    form.validateFields(async (err, values) => {
      try {
        if (!err) {
          this.setState({ submitLoading: true });
          let {
            banner,
            endUtcDate,
            planConstraint,
            maxRedeem,
            maxRedeemPerUser,
            isActive,
            discount,
            maxDiscount,
            description,
            medSys,
            phone
          } = values;

          if (banner === undefined) {
            banner = editData.banner;
          } else {
            banner = banner[0].response.imgUrl;
          }

          const couponData = {
            banner,
            terms: values.terms
              ? values.terms.filter((entry: any) => {
                  return entry.trim() !== '';
                })
              : [],
            endUtcDate: moment(endUtcDate)
              .utc()
              .endOf()
              .format(),
            planConstraint,
            maxRedeem,
            maxRedeemPerUser,
            isActive,
            discount,
            maxDiscount,
            description,
            medSys,
            phone: convertToPhoneArray(phone)
          };

          const { couponid } = editData;

          const { message, status } = await editCoupon(couponData, couponid);
          if (status === 201) {
            form.resetFields();
            fetchAllCoupons();
            notification.open({
              message,
              duration: 5000,
              icon: <Icon type="smile" style={{ color: '#1DA57A' }} />
            });
          }
          this.setState({ submitLoading: false });
          history.push('/coupon');
        }
      } catch (error) {
        const { message } = error;
        this.setState({ submitLoading: false });
        notification.open({
          message: error.message ? message : 'Please try after sometimes',
          icon: <Icon type="cry" style={{ color: 'tomato' }} />
        });
      }
    });
  };

  normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e && e.fileList;
  };

  onUpload = async (uploadObj: any) => {
    try {
      const { file } = uploadObj;
      const path = `banner/${file.name}`;
      const uploadRef = firebase.storage().ref();
      const uploadTask = uploadRef.child(path).put(file);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadObj.onProgress({ percent: progress });
        },
        (error: any) => {
          uploadObj.onError(error.code);
        },
        async () => {
          const imgUrl = await uploadTask.snapshot.ref.getDownloadURL();
          uploadObj.file.thumbUrl = imgUrl;
          uploadObj.onSuccess({ imgUrl });
          this.setState({ prevView: imgUrl });
        }
      );
    } catch (error) {
      // console.log(error);
    }
  };

  navigateToCoupons = () => {
    const { history } = this.props;
    history.push('/coupon');
  };

  remove = (k: number) => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const terms = form.getFieldValue('terms');

    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter((key: number) => {
        return key !== k;
      })
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');

    const nextKeys = keys.concat(this.id++);
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  render() {
    const { form, location } = this.props;
    const { submitLoading, prevView } = this.state;
    const { getFieldDecorator, getFieldValue } = form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 6 }
    };

    let { editData } = location.state;

    getFieldDecorator('keys', {
      initialValue: editData.terms.map((_: string, i: number) => {
        return i.toString();
      }),
      rules: [{ required: false, message: 'Terms and Condition required' }]
    });
    // {
    //   getFieldDecorator('terms', {
    //     initialValue: editData.terms
    //   });
    // }
    const keys = getFieldValue('keys');

    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Coupon</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Coupon</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledLayout>
          <Row type="flex" align="middle">
            <Col span={12}>
              <BackArrow onClick={this.navigateToCoupons} type="arrow-left" />
            </Col>
            <Col span={12}>
              <StyledH1>Edit Coupon</StyledH1>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col span={24} offset={6}>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="Name" hasFeedback>
                  <Input
                    value={editData.name}
                    disabled={editData}
                    placeholder="Coupon name"
                  />
                </Form.Item>

                <Form.Item label="Type" hasFeedback>
                  <Select
                    value={editData.type}
                    disabled={editData}
                    placeholder="Select coupon type"
                  >
                    <Option value="flat">Flat</Option>
                    <Option value="percentage">Percentage</Option>
                  </Select>
                </Form.Item>

                <Form.Item label="Coupon Constraint" hasFeedback>
                  {getFieldDecorator('planConstraint', {
                    initialValue: editData.planConstraint || 'appointments',
                    validateTrigger: ['onChange']
                  })(
                    <Select
                      mode="multiple"
                      placeholder="Select one or more constraint"
                    >
                      <Option value="appointment">Appointment</Option>
                      <Option value="medOrder">Medicine Order</Option>
                      <Option value="product">Product</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Mode" hasFeedback>
                  <Select
                    value={editData.mode}
                    disabled={editData}
                    placeholder="Select coupon mode"
                  >
                    <Option value="voucher">Voucher</Option>
                    <Option value="coupon">Coupon</Option>
                  </Select>
                </Form.Item>

                <Form.Item label="Medical System" hasFeedback>
                  {getFieldDecorator('medSys', {
                    initialValue: editData.medSys || []
                  })(
                    <Select mode="multiple" placeholder="Select Medical System">
                      <Option value="ayurveda">Ayurveda</Option>
                      <Option value="siddha">Siddha</Option>
                      <Option value="homeopathy">Homeopathy</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Currency Code" hasFeedback>
                  <Select
                    value={editData.currencyCode}
                    disabled={editData}
                    placeholder="Select currency type"
                  >
                    <Option value="INR">INR</Option>
                  </Select>
                </Form.Item>

                <Form.Item label="Coupon Code">
                  <Input
                    value={editData.code}
                    disabled={editData}
                    placeholder="Coupon Code"
                  />
                </Form.Item>

                <Form.Item label="Start Date" hasFeedback>
                  <DatePicker
                    value={moment(editData.startUtcDate)}
                    disabled={editData}
                    placeholder="Start Date"
                  />
                </Form.Item>

                <Form.Item label="End Date" hasFeedback>
                  {getFieldDecorator('endUtcDate', {
                    validateTrigger: ['onChange'],
                    initialValue: moment(editData.endUtcDate)
                  })(<DatePicker placeholder="End Date" />)}
                </Form.Item>

                <Form.Item label="Maximum Redeem">
                  {getFieldDecorator('maxRedeem', {
                    validateTrigger: ['onChange'],
                    initialValue: editData.maxRedeem
                  })(<InputNumber min={1} />)}
                </Form.Item>

                <Form.Item label="Maximum Redeem Per Person">
                  {getFieldDecorator('maxRedeemPerUser', {
                    validateTrigger: ['onChange'],
                    initialValue: editData.maxRedeemPerUser
                  })(<InputNumber min={1} />)}
                </Form.Item>

                {/* <Form.Item label="Discound">
                  <InputNumber value={editData.discound} disabled={editData} />
                </Form.Item> */}

                <Form.Item label="Discount(₹)">
                  {getFieldDecorator('discount', {
                    validateTrigger: ['onChange'],
                    initialValue: editData.discount
                  })(<InputNumber />)}
                </Form.Item>

                <Form.Item label="Maximum Discount(₹)">
                  {getFieldDecorator('maxDiscount', {
                    validateTrigger: ['onChange'],
                    initialValue: editData.maxDiscount
                  })(<InputNumber />)}
                </Form.Item>

                <Form.Item label="Coupon Status">
                  {getFieldDecorator('isActive', {
                    validateTrigger: ['onChange'],
                    initialValue: editData.isActive,
                    valuePropName: 'checked'
                  })(<Switch />)}
                </Form.Item>

                <Form.Item label="Terms & Conditions">
                  {keys.map((k: number, index: number) => (
                    <Form.Item required={false} key={k}>
                      <Row type="flex" gutter={4}>
                        <Col span={20}>
                          {getFieldDecorator(`terms[${k}]`, {
                            initialValue: editData.terms[k],
                            validateTrigger: ['onChange', 'onBlur']
                          })(<Input placeholder="Add Terms and Condition" />)}
                        </Col>
                        <Col span={2}>
                          {keys.length > 1 ? (
                            <Icon
                              type="minus-circle-o"
                              onClick={() => this.remove(k)}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Button type="dashed" onClick={this.add}>
                    <Icon type="plus" /> Add
                  </Button>
                </Form.Item>

                <Form.Item label="Mobile Number/s">
                  {getFieldDecorator('phone', {
                    validateTrigger: ['onChange'],
                    initialValue: editData.phone
                      ? editData.phone.toString()
                      : ''
                  })(<Input.TextArea placeholder="Add Mobile Number/s" />)}
                </Form.Item>

                <Form.Item label="Description">
                  {getFieldDecorator('description', {
                    validateTrigger: ['onChange'],
                    initialValue: editData.description
                  })(<Input placeholder="Add Description about coupon" />)}
                </Form.Item>

                <Form.Item label="Dragger">
                  <Row gutter={16}>
                    <Col span={18}>
                      {getFieldDecorator('banner', {
                        valuePropName: 'fileList',
                        getValueFromEvent: this.normFile
                      })(
                        <Upload.Dragger
                          customRequest={option => this.onUpload(option)}
                          multiple={false}
                          name="files"
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                          </p>
                        </Upload.Dragger>
                      )}
                    </Col>
                    <Col span={6}>
                      {prevView ? (
                        <img
                          src={prevView}
                          alt="banner"
                          style={{
                            width: 180,
                            height: 80,
                            objectFit: 'contain'
                          }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Button
                    loading={submitLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  code: state.coupon.code
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  Form.create<CouponForm & FormComponentProps>({
    name: 'CreateCoupon'
  })
)(EditCoupon);

const StyledLayout = styled.div`
  background: #fff;
  border-radius: 5px;
`;

const StyledH1 = styled.h1`
  font-size: 20px;
  margin: 16px 0;
`;

const BackArrow = styled(Icon)`
  cursor: pointer;
  font-size: 22px;
  font-weight: bolder;
  padding-left: 16px;
`;
