import React from 'react';

interface Label {
  name: string;
  elemType?: string;
  required?: boolean;
}

const Label: React.FC<Label> = (props: Label) => {
  const { name, elemType, required } = props;

  return (
    <p
      className={`${
        elemType === 'inline' ? 'inline' : 'block'
      } text-grey-700 text-base font-bold pr-2 mb-2`}
    >
      {name}
      {required ? <span className="text-red-600 pl-1">*</span> : <span />}
    </p>
  );
};

export default Label;
