import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CACHE_KEYS } from './utils/constant';

if (localStorage.getItem(CACHE_KEYS.MODE)) {
  localStorage.setItem(CACHE_KEYS.MODE, 'dev');
}

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
