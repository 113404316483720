import React, { PureComponent } from 'react';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { Breadcrumb, Layout, Table, Row, Col, Input, Avatar, Icon } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { Props } from './couponRecords';
import moment from 'moment';
import { PaginationConfig } from 'antd/lib/pagination';

const { Search } = Input;

class CouponRecords extends PureComponent<Props> {
  columns: Array<any> = [
    {
      title: 'User',
      dataIndex: 'name',
      render: (text: string, record: any) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{text}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>{record.phone}</i>
          </div>
        );
      }
    },
    {
      title: 'Code',
      dataIndex: 'coupon.code'
    },
    {
      title: 'Mode',
      dataIndex: 'coupon.mode'
    },
    {
      title: 'Type',
      dataIndex: 'coupon.type'
    },
    {
      title: 'Campaign',
      dataIndex: 'coupon.name'
    },
    {
      title: 'Discount',
      dataIndex: 'coupon',
      render: (coupon: any) => {
        if (coupon)
          return <p>{`₹${coupon.discount} (paid ₹${coupon.discountedFee})`}</p>;
        return <div />;
      }
    },
    {
      title: 'Status',
      dataIndex: 'isRedeemed',
      render: (isRedeemed: boolean) => {
        if (isRedeemed) {
          return (
            <Icon
              type="check-circle"
              theme="twoTone"
              style={{ fontSize: '26px' }}
              twoToneColor="#52c41a"
            />
          );
        }
        return null;
      }
    },
    {
      title: 'Redeem Date',
      dataIndex: 'redeemedDate',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (redeemedDate: string) => {
        const time = moment(redeemedDate).utcOffset('+05:30');
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{time.format('DD/MM/YYYY')}</span>
            <i style={{ color: '#757575', fontSize: '12px' }}>
              {time.format('hh:mm A')}
            </i>
          </div>
        );
      }
    }
  ];

  componentDidMount() {
    const { fetchAllCouponRecords } = this.props;
    fetchAllCouponRecords();
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { fetchAllCouponRecords } = this.props;
    if (e.target.value.length === 0) {
      fetchAllCouponRecords();
    }
  };

  onTableChange = async (pagination: PaginationConfig, _: any, sorter: any) => {
    const { fetchAllCouponRecords } = this.props;
    fetchAllCouponRecords(null, sorter.order);
  };

  render() {
    const { couponRecords, fetchAllCouponRecords } = this.props;
    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Coupon</Breadcrumb.Item>
          <Breadcrumb.Item>Coupon Records</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledLayout>
          <SearchWrapper type="flex" justify="center">
            <Col span={12}>
              <Search
                onChange={this.onChange}
                placeholder="Search terms can be user's name/phone, couponcode or campaign name"
                enterButton="Search"
                size="large"
                onSearch={value => fetchAllCouponRecords(value)}
              />
            </Col>
          </SearchWrapper>
          <Table
            columns={this.columns}
            dataSource={couponRecords}
            onChange={this.onTableChange}
          />
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  couponRecords: state.coupon.couponRecords
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) =>
  bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CouponRecords);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
  min-height: 80vh;
`;

const SearchWrapper = styled(Row)`
  padding: 20px;
`;
