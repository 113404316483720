import axios from 'axios';
import firebase from '../firebaseConfig';
import { CACHE_KEYS } from './constant';

const getIdToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      if (user) {
        user.getIdToken().then(
          idToken => {
            resolve(idToken);
          },
          error => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  });
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000
});

api.interceptors.request.use(
  async config => {
    // Do something before request is sent
    const token = await getIdToken();
    // console.log(token);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['mode'] = localStorage.getItem(CACHE_KEYS.MODE) || 'dev';
    // console.log(`Mode in header :: ${localStorage.getItem(CACHE_KEYS.MODE)}`);
    // console.log('Current Logged User ', token);
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
