import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '../components/auth';
import Loader from '../components/loader';

const PrivateRoute: React.FC<any> = ({
  component: RouteComponent,
  ...rest
}) => {
  const { currentUser, isLoading } = useContext(AuthContext);
  console.log(isLoading);
  return (
    <Route
      {...rest}
      render={props => {
        return isLoading ? (
          <Loader />
        ) : !!currentUser ? (
          <RouteComponent {...props} />
        ) : (
          <Redirect to={'/login'} />
        );
      }}
    />
  );
};

export default PrivateRoute;
