import { Icon, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import * as firebase from 'firebase';
import React from 'react';

interface Props {
  setGallery: Function;
  showError: boolean | undefined;
  errMsg: any;
  toggleModal: Function;
}

export const TherapyGalleryModal: React.FC<Props> = (props: Props) => {
  const { setGallery, showError, toggleModal, errMsg } = props;

  const gallery: string[] = [];

  const onUpload = async (uploadObj: any) => {
    try {
      const { file } = uploadObj;
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      const uploadTask = uploadRef.child(path).put(file);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadObj.onProgress({ percent: progress });
        },
        (error: any) => {
          console.log(error);
          uploadObj.onError(error.code);
          setGallery(gallery);
        },
        async () => {
          const imgUrl = await uploadTask.snapshot.ref.getDownloadURL();
          uploadObj.file.thumbUrl = imgUrl;
          uploadObj.onSuccess({ imgUrl });
          gallery.push(imgUrl);
          setGallery(gallery);
        }
      );
    } catch (error) {
      console.log(error);
      setGallery(gallery);
    }
  };

  const onRemove = async (file: UploadFile) => {
    try {
      const path = `therapy/${file.name}`;
      const uploadRef = firebase.storage().ref();
      uploadRef.child(path).delete();
      setGallery(gallery);
    } catch (error) {
      setGallery(gallery);
    }
  };

  return (
    <div>
      <p className="block text-grey-700 text-center text-base font-bold pr-2 mb-2">
        Upload Avatar
      </p>
      <Upload.Dragger
        customRequest={onUpload}
        onRemove={onRemove}
        multiple
        name="files"
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Upload.Dragger>
      {showError && <span className="mt-3 text-red-500 pl-2">{errMsg}</span>}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => toggleModal()}
          className="bg-primary text-white font-bold py-2 px-4 rounded-full"
        >
          Submit
        </button>
      </div>
    </div>
  );
};
