import './style.css';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Carousel, DatePicker, Icon, Modal, Popconfirm, Select, Switch, Tooltip } from 'antd';
import { FormikProps, withFormik } from 'formik';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';

import Avatar from '../../assets/avatar.png';
import Placeholder from '../../assets/placeholder.jpg';
import Loader from '../../components/loader';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { actionCreators } from '../../redux/actions/ActionCreators';
import { AppState } from '../../redux/reducers';
import StateList from '../../utils/indianState.json';
import { AddTherapyFormValues, IHours, IService, Props, State } from './addTherapyCentre';
import AddService from './components/addService';
import { AddSlotButton } from './components/addSlotButton';
import { TherapyAvatarModal } from './components/avatarModal';
import { TherapyGalleryModal } from './components/galleryModal';
import Label from './components/label';
import { SubCategoryTag } from './components/subCategoryTag';
import { TimeTag } from './components/tag';

const amenities = ['Cark Parking', 'Bike Parking', 'AC', 'WI-FI', 'Hair wash'];
const payment = ['Card', 'Cash', 'Google-Pay', 'PayTM', 'PhonePe'];

class AddTherapyCentre extends PureComponent<
  Props & FormikProps<AddTherapyFormValues>,
  State
> {
  state: State = {
    avatarModal: false,
    galleryModal: false,
    addService: false,
    editService: false,
  };

  componentDidMount() {
    const {
      fetchTherapyUserProfile,
      getCategoriesAndSubCategories,
    } = this.props;
    getCategoriesAndSubCategories();
    fetchTherapyUserProfile();
  }

  toggleAvatarModal = () => {
    this.setState((prevState) => ({
      avatarModal: !prevState.avatarModal,
    }));
  };

  toggleGalleryModal = () => {
    this.setState((prevState) => ({
      galleryModal: !prevState.galleryModal,
    }));
  };

  renderCarousel = () => {
    const { values } = this.props;
    return values.gallery.map((item) => (
      <div className="w-full h-64 bg-blue-600">
        <img className="bg-cover" src={item} alt="gallery" />
      </div>
    ));
  };

  onAddSlot = (hours: IHours[], index: number) => {
    const { setFieldValue, values } = this.props;
    const timings = [...values.timings];
    timings[index].hours = [...timings[index].hours, ...hours];
    setFieldValue('timings', timings);
  };

  onEditSlot = (hour: IHours, index: number, hIndex: number) => {
    const { setFieldValue, values } = this.props;
    const timings = [...values.timings];
    timings[index].hours[hIndex] = hour;
    setFieldValue('timings', timings);
  };

  onDeleteSlot = (index: number, hIndex: number) => {
    const { setFieldValue, values } = this.props;
    const timings = [...values.timings];
    timings[index].hours.splice(hIndex, 1);
    setFieldValue('timings', timings);
  };

  onSwichClick = (checked: boolean, index: number) => {
    const { setFieldValue, values } = this.props;
    const timings = [...values.timings];
    timings[index].isActive = checked;
    setFieldValue('timings', timings);
  };

  toggleAddService = () => {
    this.setState({
      addService: !this.state.addService,
    });
  };

  onAddService = (service: IService) => {
    const { setFieldValue, values } = this.props;
    const services = [...values.services, service];
    setFieldValue('services', services);
  };

  onDeleteSubCategory = (index: number, sIndex: number) => {
    const { setFieldValue, values } = this.props;
    const services = [...values.services];
    services[index].subCategories.splice(sIndex, 1);
    setFieldValue('services', services);
  };

  onCategorySwich = (checked: boolean, index: number) => {
    const { setFieldValue, values } = this.props;
    const services = [...values.services];
    services[index].isActive = checked;
    setFieldValue('services', services);
  };

  onCategoryDelete = (index: number) => {
    const { setFieldValue, values } = this.props;
    const services = [...values.services];
    services.splice(index, 1);
    setFieldValue('services', services);
  };

  addMoreSubCategory = (value) => {
    console.log(value);
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleBlur,
      handleSubmit,
      handleChange,
      setFieldValue,
      isLoading,
      history,
      userProfiles,
    } = this.props;
    console.log(values);
    const isEdit = history.location.state?.isEdit;
    const { avatarModal, galleryModal, addService, editService } = this.state;
    return (
      <div>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Therapy</Breadcrumb.Item>
          <Breadcrumb.Item>Add Therapy Centres</Breadcrumb.Item>
        </StyledBreadcrumb>
        {isLoading ? <Loader /> : null}
        <Modal
          visible={avatarModal}
          footer={null}
          onCancel={this.toggleAvatarModal}
        >
          <TherapyAvatarModal
            showError={touched.avatar && errors.avatar !== undefined}
            errMsg={errors.avatar}
            toggleModal={this.toggleAvatarModal}
            setAvatar={(avatar: string) => setFieldValue('avatar', avatar)}
          />
        </Modal>
        <h3 className="flex justify-center text-xl text-bold text-gray-700 mt-4">
          {`${isEdit ? 'Edit' : 'Add'} Therapy Centre`}
        </h3>
        <form className="w-full px-24" onSubmit={handleSubmit}>
          <div className="bg-white px-16 py-8">
            <section className="flex">
              <div className="w-24 h-24 border-grey border-2 rounded-full mr-12 relative">
                <img
                  className="rounded-full"
                  src={values.avatar}
                  alt={values.name}
                />
                <div
                  onClick={this.toggleAvatarModal}
                  className="absolute cursor-pointer bottom-0 right-0 bg-white border-gray-300 border-2 w-8 h-8 flex justify-center items-center rounded-full"
                >
                  <Icon type="edit" />
                </div>
              </div>
              <div className="w-2/3 my-4">
                <Label name="Therapy Centre Name" required />
                <input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: XYZ Therapy Centre"
                />
                {errors.name && touched.name && (
                  <span className="mt-3 text-red-500 pl-2">{errors.name}</span>
                )}
              </div>
            </section>
            <div className="flex w-full my-4">
              <div className="w-1/3 pr-4">
                <Label name="Mobile Number" required />
                <input
                  name="mobile"
                  type="tel"
                  maxLength={13}
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: +9193602XXXXX"
                />
                {errors.mobile && touched.mobile && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.mobile}
                  </span>
                )}
              </div>
              <div className="w-1/3 px-4">
                <Label name="Landline Number" />
                <input
                  name="landline"
                  type="tel"
                  value={values.landline}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: +044354840"
                />
              </div>
              <div className="w-1/3 px-4">
                <div className="flex">
                  <Label name="Other Numbers" />{' '}
                  <div>
                    <Tooltip
                      placement="top"
                      title={
                        'You can add multiple numbers by comma seperating the numbers'
                      }
                    >
                      <Icon type="info-circle" style={{ color: '#3392FF' }} />
                    </Tooltip>
                  </div>
                </div>
                <input
                  name="other"
                  value={values.other}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: +9193602XXXXX, +9189602XXXXX "
                />
              </div>
            </div>

            <div className="w-full flex my-4">
              <div className="w-1/3 pr-4">
                <Label name="Owner Name" required />
                <input
                  name="ownerName"
                  value={values.ownerName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: Kesavan"
                />
                {errors.ownerName && touched.ownerName && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.ownerName}
                  </span>
                )}
              </div>
              <div className="w-1/3 px-4">
                <Label name="Owner Number" required />
                <input
                  name="ownerPhone"
                  type="tel"
                  value={values.ownerPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: +91867890XXXX"
                />
                {errors.ownerPhone && touched.ownerPhone && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.ownerPhone}
                  </span>
                )}
              </div>
              <div className="w-1/3 px-4">
                <Label name="Established Date" required />
                <DatePicker
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="AddTherapyForm"
                  value={values.establishedAt}
                  onChange={handleChange('establishedAt')}
                />
                {errors.establishedAt && touched.establishedAt && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.establishedAt}
                  </span>
                )}
              </div>
            </div>

            <div className="flex w-full">
              <div className="my-4">
                <Label elemType="inline" name="Active" required />
                <Switch
                  checked={values.isActive}
                  onChange={handleChange('isActive')}
                />
              </div>
              <div className="pl-8 my-4">
                <Label elemType="inline" name="Online" required />
                <Switch
                  checked={values.online}
                  onChange={handleChange('online')}
                />
              </div>
            </div>

            <div className="w-full flex my-4">
              <div className="w-1/3 pr-4">
                <Label name="Door Number" required />
                <input
                  name="doorNo"
                  value={values.doorNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: 42/51-7"
                />
                {errors.doorNo && touched.doorNo && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.doorNo}
                  </span>
                )}
              </div>
              <div className="w-1/3 px-4">
                <Label name="Street" required />
                <input
                  name="street"
                  value={values.street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: MGR Street"
                />
                {errors.street && touched.street && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.street}
                  </span>
                )}
              </div>
              <div className="w-1/3 px-4">
                <Label name="District" required />
                <input
                  name="district"
                  value={values.district}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: Chennai"
                />
                {errors.district && touched.district && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.district}
                  </span>
                )}
              </div>
            </div>

            <div className="w-full flex my-4">
              <div className="w-1/3 pr-4">
                <Label name="State" required />
                <div className="relative">
                  <Select
                    id="custom-select"
                    placeholder="Select State"
                    onChange={handleChange('state')}
                    onSearch={(v) => v}
                    showArrow
                    showSearch
                    defaultValue={[values.state]}
                  >
                    {StateList.states.map((state) => (
                      <Select.Option key={state.capital} value={state.name}>
                        {state.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.state && touched.state && (
                  <span className="mt-3 text-red-500 pl-2">{errors.state}</span>
                )}
              </div>
              <div className="w-1/3 px-4">
                <Label name="Country" required />
                <input
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: India"
                />
                {errors.country && touched.country && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.country}
                  </span>
                )}
              </div>
              <div className="w-1/3 px-4">
                <Label name="Pincode" required />
                <input
                  name="pincode"
                  value={values.pincode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Ex: 600091"
                />
                {errors.pincode && touched.pincode && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.pincode}
                  </span>
                )}
              </div>
            </div>

            <div className="w-1/3 mb-4">
              <Label name="Landmarks" />
              <input
                name="landmarks"
                value={values.landmarks}
                onChange={handleChange}
                onBlur={handleBlur}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Ex: near K.S Towers, Parallel to KC Complex"
              />
            </div>
            <div className="w-1/3 mb-4">
              <Label name="Therapists" />
              <div className="relative">
                <Select
                  id="custom-select"
                  placeholder="Select Therapists"
                  onChange={handleChange('therapist')}
                  onSearch={(v) => v}
                  showArrow
                  showSearch
                  mode="multiple"
                  maxTagCount={5}
                  maxTagTextLength={6}
                  defaultValue={values.therapist}
                >
                  {userProfiles.map((profile: any) => (
                    <Select.Option key={profile._id} value={profile._id}>
                      {profile.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="flex mt-8">
              <div className="w-1/2">
                <Carousel autoplay>{this.renderCarousel()}</Carousel>
                <div
                  onClick={this.toggleGalleryModal}
                  className="w-56 cursor-pointer flex flex-row justify-center items-center bg-primary text-white font-bold py-2 mt-4 px-4 rounded"
                >
                  <PlusOutlined />
                  <p className="m-0 pl-2">Add Gallery</p>
                </div>
              </div>

              <Modal
                visible={galleryModal}
                footer={null}
                onCancel={this.toggleGalleryModal}
              >
                <TherapyGalleryModal
                  showError={touched.gallery && errors.gallery !== undefined}
                  errMsg={errors.gallery}
                  toggleModal={this.toggleGalleryModal}
                  setGallery={(gallery: string[]) => {
                    console.log(`gallery: ${gallery}`);
                    setFieldValue('gallery', gallery);
                  }}
                />
              </Modal>
            </div>

            <div className="w-full flex my-4">
              <div className="w-1/2 pr-4">
                <Label name="Amenities" required />
                <div className="relative">
                  <Select
                    id="custom-select"
                    placeholder="Select Amenities"
                    onChange={handleChange('amenities')}
                    onSearch={(v) => v}
                    showArrow
                    showSearch
                    mode="multiple"
                    maxTagCount={5}
                    maxTagTextLength={6}
                    defaultValue={values.amenities}
                  >
                    {amenities.map((amenity) => (
                      <Select.Option key={amenity} value={amenity}>
                        {amenity}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.amenities && touched.amenities && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.amenities}
                  </span>
                )}
              </div>
              <div className="w-1/2 pr-4">
                <Label name="Payment Methods" required />
                <div className="relative">
                  <Select
                    id="custom-select"
                    placeholder="Select Payment Type"
                    onChange={handleChange('payment')}
                    onSearch={(v) => v}
                    showArrow
                    showSearch
                    mode="multiple"
                    maxTagCount={5}
                    maxTagTextLength={6}
                    defaultValue={values.payment}
                  >
                    {payment.map((type) => (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors.payment && touched.payment && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.payment}
                  </span>
                )}
              </div>
            </div>
            <Label name="Timings" required />
            {values.timings.map((item, i) => (
              <div
                className={`flex flex-row py-1 rounded-md border ${
                  i > 0 ? 'border-t-0' : ''
                } border-gray-400`}
              >
                <div className="w-20 border-r border-gray-400 flex justify-center items-center">
                  <p className="m-0 text-base text-gray-800 ">{item.label}</p>
                </div>
                <div className="w-full flex flex-wrap border-r border-gray-400 flex items-center p-3">
                  {item.hours.map((hour, hIndex) => (
                    <TimeTag
                      hour={hour}
                      isActive={hour.isActive}
                      index={i}
                      hIndex={hIndex}
                      onEditSlot={this.onEditSlot}
                      onDeleteSlot={this.onDeleteSlot}
                    />
                  ))}

                  <AddSlotButton index={i} onAddSlot={this.onAddSlot} />
                </div>
                <div className="w-20 border-r border-gray-400 flex justify-center items-center">
                  <Switch
                    size="small"
                    checked={item.isActive}
                    onChange={(checked) => this.onSwichClick(checked, i)}
                  />
                </div>
                <div className="w-16  border-r-0 flex justify-center items-center">
                  <EditOutlined />
                </div>
                {errors.timings && touched.timings && (
                  <span className="mt-3 text-red-500 pl-2">
                    {errors.timings}
                  </span>
                )}
              </div>
            ))}
            <div className="mt-2">
              <Label name="Services" required />
              <Modal
                width={1200}
                visible={addService}
                footer={null}
                onCancel={this.toggleAddService}
                destroyOnClose
              >
                <AddService
                  onAddService={this.onAddService}
                  onCancel={this.toggleAddService}
                />
              </Modal>
              {values.services.map((category, i) => {
                return (
                  <div
                    className={`flex flex-row py-1 rounded-md border ${
                      i > 0 ? 'border-t-0' : ''
                    } border-gray-400`}
                  >
                    <div className="w-1/3 border-r border-gray-400 flex justify-center items-center">
                      <p className="m-0 text-base text-gray-800 ">
                        {category.name}
                      </p>
                    </div>
                    <div className="w-full flex flex-wrap border-r border-gray-400 flex items-center p-3">
                      {category.subCategories.map((subCategory, sIndex) => (
                        <SubCategoryTag
                          subCategory={subCategory}
                          index={i}
                          sIndex={sIndex}
                          onDeleteSubCategory={this.onDeleteSubCategory}
                        />
                      ))}
                    </div>
                    <div className="w-20 border-r border-gray-400 flex justify-center items-center">
                      <Switch
                        size="small"
                        checked={category.isActive}
                        onChange={(checked) => this.onCategorySwich(checked, i)}
                      />
                    </div>
                    <div className="w-16  border-r-0 flex justify-center items-center">
                      <Popconfirm
                        title="Are you sure delete?"
                        onConfirm={() => this.onCategoryDelete(i)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </div>
                  </div>
                );
              })}
              <div
                onClick={this.toggleAddService}
                className="w-56 cursor-pointer flex flex-row justify-center items-center bg-primary text-white font-bold py-2 mt-4 px-4 rounded"
              >
                <PlusOutlined />
                <p className="m-0 pl-2">Add New Service</p>
              </div>
              {errors.services && touched.services && (
                <span className="mt-3 text-red-500 pl-2">
                  {errors.services}
                </span>
              )}
            </div>
            <button
              className="bg-primary text-white font-bold py-2 px-4 rounded-full mt-4"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const EnhancedForm = withFormik<Props, AddTherapyFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const record = props.history.location.state?.record;
    return {
      name: record?.name || '',
      mobile: record?.phone?.mobile || '',
      landline: record?.phone?.landline || '',
      other: record?.phone?.other.toString() || '',
      doorNo: record?.address?.doorNo || '',
      street: record?.address?.street || '',
      district: record?.address?.district || '',
      state: record?.address?.state || '',
      country: record?.address?.country || '',
      pincode: record?.address?.pincode || '',
      landmarks: record?.address?.landmarks?.toString() || '',
      ownerName: record?.ownerName || '',
      ownerPhone: record?.ownerPhone || '',
      medSys: record?.medSys || '',
      adminId: record?.adminId || '',
      establishedAt: moment(),
      isActive: record?.isActive ? true : false,
      online: record?.online ? true : false,
      avatar: record?.avatar || Avatar,
      therapist: record?.therapist || [],
      gallery: record?.gallery || [Placeholder],
      payment: record?.payment || [],
      amenities: record?.amenities || [],
      timings: record?.timings || [
        {
          day: 1,
          dayIndex: 'monday',
          label: 'Mon',
          isActive: true,
          hours: [],
        },
        {
          day: 2,
          dayIndex: 'tuesday',
          label: 'Tue',
          isActive: true,
          hours: [],
        },
        {
          day: 3,
          dayIndex: 'wednesday',
          label: 'Wed',
          isActive: true,
          hours: [],
        },
        {
          day: 4,
          dayIndex: 'thursday',
          label: 'Thu',
          isActive: true,
          hours: [],
        },
        {
          day: 5,
          dayIndex: 'friday',
          label: 'Fri',
          isActive: true,
          hours: [],
        },
        {
          day: 6,
          dayIndex: 'saturday',
          label: 'Sat',
          isActive: false,
          hours: [],
        },
        {
          day: 7,
          dayIndex: 'sunday',
          label: 'Sun',
          isActive: false,
          hours: [],
        },
      ],
      services: record?.services || [],
    };
  },
  handleSubmit: (values: AddTherapyFormValues, { props }) => {
    const isEdit = props.history.location.state?.isEdit;
    const record = props.history.location.state?.record;
    const {
      mobile,
      landline,
      other,
      doorNo,
      street,
      district,
      state,
      country,
      pincode,
      landmarks,
      ...rest
    } = values;
    const profile = {
      ...rest,
      address: {
        doorNo,
        street,
        district,
        state,
        country,
        pincode,
        landmark: landmarks.split(','),
      },
      phone: { mobile, landline, other: other.split(',') },
    };
    if (isEdit) {
      return props.updateTherapyCenter({
        profileData: profile,
        profileId: record._id,
      });
    }
    return props.createTherapyCenter(profile);
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required(`Therapy centre name required`),
    mobile: Yup.string().required('Mobile Number required'),
    landline: Yup.string(),
    others: Yup.string(),
    doorNo: Yup.string().required('Door No required'),
    street: Yup.string().required('Street required'),
    district: Yup.string().required('District required'),
    state: Yup.string().required('State required'),
    country: Yup.string().required('Country required'),
    pincode: Yup.string().required('Pincode required'),
    landmarks: Yup.string(),
    ownerName: Yup.string().required('Owner Name required'),
    ownerPhone: Yup.string().required('Owner Phone required'),
    establishedAt: Yup.string().required('Estalished Date required'),
    gallery: Yup.array().of(Yup.string()).required('Gallery required'),
    amenities: Yup.array().of(Yup.string()).required('Amenities required'),
    payment: Yup.array().of(Yup.string()).required('Payment required'),
    timings: Yup.array().required('Timing required'),
    services: Yup.array().required('Services required'),
  }),
})(AddTherapyCentre);

const mapStateToProps = (state: AppState) => ({
  isLoading: state.therapy.therapyCenterLoading,
  isCreated: state.therapy.therapyCenterCreated,
  subCategories: state.therapy.subCategories,
  userProfiles: state.therapy.userProfiles,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);
