import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Switch,
  Upload,
  Icon,
  Button,
  Modal
} from 'antd';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators, compose } from 'redux';
import * as firebase from 'firebase/app';
import { FormComponentProps } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';

import { actionCreators } from '../../redux/actions/ActionCreators';
import { withRouter } from 'react-router';
import StyledBreadcrumb from '../../components/styledBreadcrumb';
import { Props, CategoryForm } from './createProductCategory';
import { AppState } from '../../redux/reducers';

class CreateProductCategories extends PureComponent<
  Props & FormComponentProps
> {
  handleSubmit = () => {};

  normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e && e.fileList;
  };

  onUpload = async (uploadObj: any) => {
    try {
      const { file } = uploadObj;
      const path = `banner/${file.name}`;
      const uploadRef = firebase.storage().ref();
      const uploadTask = uploadRef.child(path).put(file);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadObj.onProgress({ percent: progress });
        },
        (error: any) => {
          uploadObj.onError(error.code);
        },
        async () => {
          const imgUrl = await uploadTask.snapshot.ref.getDownloadURL();
          uploadObj.file.thumbUrl = imgUrl;
          uploadObj.onSuccess({ imgUrl });
        }
      );
    } catch (error) {
      // console.log(error);
    }
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 6 }
    };

    return (
      <>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Add product categories</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledLayout>
          <Row type="flex" justify="center">
            <Col span={24} offset={6}>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Col span={24} offset={6}>
                  <StyledH1>Create Product Categories</StyledH1>
                </Col>

                <Form.Item label="Name" hasFeedback>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Coupon name required' }]
                  })(<Input placeholder="Coupon name" />)}
                </Form.Item>

                <Form.Item label="Tag Name" hasFeedback>
                  {getFieldDecorator(
                    'tagName',
                    {}
                  )(<Input placeholder="Tag Name" />)}
                </Form.Item>
                <Form.Item label="Position" hasFeedback>
                  {getFieldDecorator(
                    'position',
                    {}
                  )(<Input placeholder="Position" />)}
                </Form.Item>
                <Form.Item label="Category Status">
                  {getFieldDecorator('isActive', {
                    valuePropName: 'checked',
                    initialValue: true
                  })(<Switch />)}
                </Form.Item>
                <Form.Item label="Description" hasFeedback>
                  {getFieldDecorator('description', {
                    rules: [{ required: true, message: 'Description required' }]
                  })(<TextArea rows={5} placeholder="Product Description" />)}
                </Form.Item>
                <Form.Item label="Dragger">
                  {getFieldDecorator('iconUrl', {
                    valuePropName: 'fileList',
                    getValueFromEvent: this.normFile
                    // rules: [
                    //   { required: true, message: 'Banner image is required' }
                    // ]
                  })(
                    <Upload.Dragger
                      customRequest={option => this.onUpload(option)}
                      multiple={false}
                      name="files"
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                      </p>
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </StyledLayout>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => bindActionCreators(actionCreators, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  Form.create<CategoryForm & FormComponentProps>({
    name: 'CreateProductCategories'
  })
)(CreateProductCategories);

const StyledLayout = styled(Layout)`
  background: #fff;
  border-radius: 5px;
  min-height: 80vh;
`;

const StyledH1 = styled.h1`
  font-size: 20px;
  margin: 16px 0;
`;
